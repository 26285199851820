import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TheFooter = _resolveComponent("TheFooter")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_SparksDrawer = _resolveComponent("SparksDrawer")!
  const _component_MobileMenu = _resolveComponent("MobileMenu")!
  const _component_CookieBanner = _resolveComponent("CookieBanner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheHeader),
    _createVNode(_component_router_view),
    _createVNode(_component_TheFooter),
    _createVNode(_component_Alert),
    _createVNode(_component_SparksDrawer),
    _createVNode(_component_MobileMenu),
    _createVNode(_component_CookieBanner)
  ], 64))
}