import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex justify-end mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ArrowIcon, { class: "absolute left-3 top-[19px] text-cebai-primary-default stroke-current stroke-2" }),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.url) = $event)),
        placeholder: "e.g. https://example.com",
        class: "placeholder-cebai-grey-dark bg-cebai-grey-light rounded-md outline outline-1 outline-cebai-grey-light focus:outline-cebai-primary-default transition-all duration-300 w-full p-3 pl-9 text-lg md:text-xl"
      }, null, 512), [
        [_vModelText, _ctx.url]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasUrl)
        ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            type: "outline-dark",
            class: "mr-2",
            onClick: _ctx.clearAll
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Clear ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AppButton, {
        type: "primary",
        disabled: _ctx.isSubmitDisabled,
        "is-loading": _ctx.isLoading,
        onClick: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Generate Sparks ")
        ]),
        _: 1
      }, 8, ["disabled", "is-loading", "onClick"])
    ])
  ], 64))
}