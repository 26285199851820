import type { ExampleProblemText } from "@/types/problem";

/**
 * The text used for the examples in the ui. Each 'theme' has its own example text.
 * @constant
 */
export const exampleProblemText: ExampleProblemText = {
  general:
    "A hotel chain is revamping its procedures to check-in its guests. It wants both to increase the customer experience, reduce its costs, and provide its staff with more rewarding experiences. It sees digital kiosks connected to pre-service communication with guests as a key feature of the new procedure. However, it also wants to retain the quirkiness of the chain." as const,
  circular:
    "A local community is seeking to increase the number of shared spaces to bring residents together. The community is looking to close off streets to create places for playing and meeting. New play spaces will provide exciting new games and activities for children. New meeting spaces will increase social connections between residents. The community is also looking to reduce car use in the community, to improve the health of the local residents." as const,
};

/**
 * The miniumum character length of problem text needed in the ui for the user
 * to be able to generate sparks.
 * @constant
 */
export const minProblemTextLength = 25 as const;

/**
 * The maximum file size for uploadloading a problem, declared in bytes.
 * If you change the value here, also update the error message string
 * "UPLOAD_FILE_TOO_LARGE".
 * @constant
 */
export const maxFileSizeForProblem = 3145728 as const;
