
import { defineComponent, computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useTrackingStore } from "@/stores/tracking";
import { useSparksStore } from "@/stores/sparks";
import { useMessagesStore } from "@/stores/messages";
import { useProblemStore } from "@/stores/problem";
import { useTopicsStore } from "@/stores/topics";
import { isValidURL } from "@/utils/text";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import ArrowIcon from "@/assets/images/icon-arrow.svg";

export default defineComponent({
  components: {
    AppButton,
    ArrowIcon,
  },
  emits: ["submitted"],
  setup(props, { emit }) {
    const trackingStore = useTrackingStore();
    const sparksStore = useSparksStore();
    const messagesStore = useMessagesStore();
    const problemStore = useProblemStore();
    const topicsStore = useTopicsStore();

    const { isLoadingProblem } = storeToRefs(problemStore);
    const { isLoadingTopics } = storeToRefs(topicsStore);

    const isLoading = computed(
      () => isLoadingProblem.value || isLoadingTopics.value,
    );

    const url = ref("");
    const hasUrl = computed(() => url.value.length > 0);
    const isSubmitDisabled = computed(() => url.value.length < 1);

    const clearUrl = () => {
      url.value = "";
    };

    const clearAll = () => {
      clearUrl();
      sparksStore.setShownSparksSection(false);

      // clear out state from the store
      problemStore.clearProblem();
      problemStore.clearProblemSummary();
      sparksStore.clearSparks();
      topicsStore.clearTopics();
    };

    const handleSubmit = async () => {
      if (!isValidURL(url.value)) {
        messagesStore.addMessage("info", "URL_NOT_VALID");
        return;
      }

      sparksStore.setShownSparksSection(false);
      sparksStore.clearSparks();

      const problemText = await problemStore.getProblemTextFromUrl(url.value);
      if (!problemText) return;

      // tell parent that url has been submitted
      emit("submitted", "url");

      problemStore.setProblem(problemText);

      // the possible ideas service requires a short problem summary
      // (this runs in the background, ready for when it is needed)
      problemStore.getProblemSummary();

      const hasTopics = await topicsStore.getTopics();
      if (hasTopics) {
        topicsStore.refreshTopics();
        sparksStore.setShownSparksSection(true);
      } else {
        messagesStore.addMessage("info", "NO_EXTRACTED_TOPICS_URL");
      }

      trackingStore.trackEvent({ action: "PROBLEM_FORM_URL_SUBMIT" });
    };

    return {
      url,
      hasUrl,
      isSubmitDisabled,
      isLoading,
      clearUrl,
      clearAll,
      handleSubmit,
    };
  },
});
