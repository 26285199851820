
import { defineComponent, onMounted } from "vue";
import { useTrackingStore } from "@/stores/tracking";
import TheHeader from "@/components/layout/the-header/TheHeader.vue";
import TheFooter from "@/components/layout/the-footer/TheFooter.vue";
import Alert from "@/components/ui/alert/Alert.vue";
import SparksDrawer from "@/components/content/sparks-drawer/SparksDrawer.vue";
import MobileMenu from "@/components/content/mobile-menu/MobileMenu.vue";
import CookieBanner from "@/components/ui/cookie-banner/CookieBanner.vue";

export default defineComponent({
  components: {
    TheHeader,
    TheFooter,
    CookieBanner,
    Alert,
    SparksDrawer,
    MobileMenu,
  },
  setup() {
    const trackingStore = useTrackingStore();

    onMounted(() => {
      trackingStore.updateTrackingState();
    });
    return {};
  },
});
