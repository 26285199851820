
import { defineComponent } from "vue";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    AppContent,
    Editorial,
  },
  setup() {
    return {};
  },
});
