import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(["button group", {
      [`button--type-${_ctx.type}`]: _ctx.type,
      [`button--disabled`]: _ctx.disabled,
    }]),
    to: _ctx.to
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon"),
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["class", "to"]))
}