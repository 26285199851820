import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import type { EntityType, NamedEntity } from "@/types/topics";

/* helpers */

export const extractEntityType = (namedEntities: NamedEntity[]): EntityType => {
  // extract the entity types from the array of ner service response objects
  // (see NamedEntity type above for object structure)
  // a string operation is required on the "generic-type" property to extract
  // the last item in the url path i.e. the entity type
  // e.g. "generic-type": "http://dbpedia.org/ontology/Thing"
  const genericTypes = namedEntities.map((namedEntity) =>
    namedEntity["generic-type"].substring(
      namedEntity["generic-type"].lastIndexOf("/") + 1,
    ),
  );

  // default type is Thing
  let entityType: EntityType = "Thing";

  // replace "Thing" if another type is present in the response
  if (genericTypes.includes("Place")) {
    entityType = "Place";
  } else if (genericTypes.includes("Organisation")) {
    entityType = "Organisation";
  } else if (genericTypes.includes("Person")) {
    entityType = "Person";
  }
  return entityType;
};

/* api calls */

export const getEntityType = (text: string): AxiosPromise => {
  return apiClient.get(`/nlp/ner?text=${text}`);
};
