
import { defineComponent, PropType } from "vue";
import type { HeaderLink } from "@/types/menu";

export default defineComponent({
  props: {
    links: {
      type: Array as PropType<HeaderLink[]>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
