import { captureException } from "@/utils/errors";
import { useMessagesStore } from "@/stores/messages";
import type { Spark } from "@/types/sparks";

// Copy to clipboard using the Clipboard API
export const copyToClipboard = async (sparks: Spark[]) => {
  const { ClipboardItem } = window;
  const messagesStore = useMessagesStore();

  try {
    // ClipboardItem non-standard in Firefox (17/05/2022) - use clipboard.writeText
    if (ClipboardItem) {
      const htmlString = buildHtmlString(sparks);
      const blobInput = new Blob([htmlString], { type: "text/html" });
      const clipboardInput = new ClipboardItem({ "text/html": blobInput });
      await navigator.clipboard.write([clipboardInput]);
    } else {
      const textString = buildTextString(sparks);
      await navigator.clipboard.writeText(textString);
    }
    messagesStore.addMessage("info", "COPIED_TO_CLIPBOARD");
  } catch (error) {
    // NB error fires for old browsers without the Clipboard API
    captureException(error);
    messagesStore.addMessage("error", "ERROR_CLIPBOARD");
  }
};

const buildTextString = (sparks: Spark[]) => {
  let textString = "";

  // Add heading and date section
  textString += `DESIGN SPARKS\nDate and time: ${new Date().toLocaleString()}\n\n`;

  // Add sparks and ideas section
  textString += "SAVED SPARKS AND IDEAS\n"; // heading
  if (sparks && sparks.length) {
    const fullSpark = sparks.map(
      (spark) =>
        `${
          spark.guidances.length
            ? spark.text + " " + spark.guidances[0]
            : spark.text
        }\n`,
    );
    textString += `${fullSpark.join("\n")}\n`;
  } else {
    textString += "No sparks or ideas saved.\n\n";
  }

  return textString;
};

const buildHtmlString = (sparks: Spark[]) => {
  let htmlString = `<body>`; // define opening html string

  // Add heading and date section
  htmlString += `<h1><b>Design Sparks</b></h1>
    <p>Date and time: ${new Date().toLocaleString()}</p>`;

  // Add sparks and ideas section
  htmlString += `<h2><b>Saved Sparks and Ideas</b></h2>`; // heading
  if (sparks && sparks.length) {
    htmlString += toTableString(sparks); // build saved sparks table
  } else {
    htmlString += `<p>No sparks or ideas saved.</p>`;
  }

  // End html
  htmlString += `</body>`;

  return htmlString;
};

const toTableString = (sparks: Spark[]): string => {
  let tableString = `<table><tbody>`;

  sparks.forEach((spark: Spark) => {
    tableString += `<tr>
      <td style="border-bottom: 1px solid gray;vertical-align: top;padding:5px;">
        ${
          spark.guidances.length
            ? spark.text + " " + spark.guidances[0]
            : spark.text
        }
      </td>
    </tr>`;
  });
  tableString += "</tbody></table>";

  return tableString;
};
