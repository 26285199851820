
import { defineComponent, PropType } from "vue";
import type { Spark } from "@/types/sparks";
import { useSavedSparksStore } from "@/stores/savedSparks";

import BookmarkIcon from "@/assets/images/icon-bookmark.svg";
import BookmarkFilledIcon from "@/assets/images/icon-bookmark-filled.svg";

export default defineComponent({
  components: {
    BookmarkIcon,
    BookmarkFilledIcon,
  },
  props: {
    idea: {
      type: Object as PropType<Spark>,
      required: true,
    },
    isSaved: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const savedSparksStore = useSavedSparksStore();

    const handleBookmarkClick = () => {
      if (props.isSaved) {
        savedSparksStore.removeSavedSpark(props.idea);
      } else {
        savedSparksStore.addSavedSpark(props.idea);
      }
    };

    return {
      handleBookmarkClick,
    };
  },
});
