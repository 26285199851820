
import { defineComponent, ref, computed, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useGuidedTourStore } from "@/stores/guidedTour";
import { useProblemStore } from "@/stores/problem";
import { useSparksStore } from "@/stores/sparks";
import { useTopicsStore } from "@/stores/topics";
import { useTrackingStore } from "@/stores/tracking";
import { waitForElement } from "@/utils/dom";
import type { StepEntity } from "v-onboarding/src/types/StepEntity";
import GuidedTourContent from "./GuidedTourContent.vue";

export default defineComponent({
  components: {
    GuidedTourContent,
  },
  setup() {
    const guidedTourStore = useGuidedTourStore();
    const problemStore = useProblemStore();
    const sparksStore = useSparksStore();
    const topicsStore = useTopicsStore();
    const trackingStore = useTrackingStore();

    const { startTour } = storeToRefs(guidedTourStore);
    const { hasProblemText } = storeToRefs(problemStore);
    const { isShownSparksSection } = storeToRefs(sparksStore);

    const guidedTourContentRef = ref<InstanceType<
      typeof GuidedTourContent
    > | null>(null);

    watch(startTour, () => {
      if (startTour.value) {
        guidedTourContentRef.value?.start();
        guidedTourStore.setTourStarted();

        trackingStore.trackEvent({ action: "TOUR_START" });
      }
    });

    const activeSparkTab = computed(
      () => guidedTourStore.activeSparkTab || "trigger",
    );

    const handleFinishTour = async () => {
      guidedTourStore.setFinishTour();
      await nextTick();
      guidedTourStore.setTourFinished();
      trackingStore.trackEvent({ action: "TOUR_FINISH" });
    };

    const steps = computed<StepEntity[]>(() => [
      {
        attachTo: { element: "#heading-design-problem" },
        content: {
          title:
            "Choose how you want to input your design problem. There are 3 options to direct the AI generated sparks.",
        },
      },
      {
        attachTo: { element: "#tab-pdf" },
        content: {
          title:
            "You need to provide a description of the design problem and, if possible, emerging solutions.",
        },
      },
      {
        attachTo: { element: "#button-problem-settings" },
        content: {
          title:
            "Click 'Theme settings' to choose the theme with which Design Sparks will generate sparks and ideas.",
        },
      },
      // if the textarea is empty show the 'Example' button, else show the 'Generate Sparks' button
      ...(!hasProblemText.value
        ? [
            {
              attachTo: { element: "#button-example" },
              content: {
                title:
                  "You can click to try an example and press 'Generate Sparks' to see how it works.",
              },
            },
          ]
        : [
            {
              attachTo: { element: "#button-generate-sparks" },
              content: {
                title:
                  "Once you've entered or uploaded your problem click 'Generate Sparks' and work with the new ideas.",
              },
            },
          ]),
      {
        attachTo: { element: "#generated-sparks" },
        content: {
          title:
            "Explore the different Spark techniques. More details on these can be found in the 'Techniques' page.",
        },
        options: {
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 55],
                },
              },
            ],
          },
        },
        on: {
          beforeStep: async () => {
            if (isShownSparksSection.value) return;
            guidedTourStore.setExampleTextAndSubmit(true);
            await waitForElement(`#topics-${activeSparkTab.value}-section`);
          },
        },
      },
      {
        attachTo: { element: `#topics-${activeSparkTab.value}-section` },
        content: {
          title:
            "Click to open the 'Extracted topics' options to direct Sparks generation.",
        },
        options: {
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 6],
                },
              },
            ],
          },
        },
        on: {
          beforeStep: () => {
            topicsStore.setExpanded(true);
          },
        },
      },
      {
        attachTo: { element: `#button-topic-${activeSparkTab.value}-settings` },
        content: {
          title:
            "Click 'Topic settings' to include top, rare and random topics in the Sparks generation, and to control the number of Sparks to generate.",
        },
        options: {
          popper: {
            placement: "bottom",
          },
        },
      },
      {
        attachTo: { element: `#button-bot-${activeSparkTab.value}-0` },
        content: {
          title: "Click the bot to access possible ideas from GPT.",
        },
        options: {
          popper: {
            placement: "bottom",
          },
        },
      },
      {
        attachTo: { element: `#button-${activeSparkTab.value}-refresh-sparks` },
        content: {
          title:
            "Click the 'Refresh Sparks' button to generate another 5 sparks.",
        },
      },
      {
        attachTo: { element: `#button-bookmark-${activeSparkTab.value}-0` },
        content: {
          title: "Save any Spark by clicking the bookmark icon next to it.",
        },
        options: {
          popper: {
            placement: "bottom",
          },
        },
      },
      {
        attachTo: { element: "#button-sparks" },
        content: {
          title:
            "Any Sparks you bookmark will be available here. Click the button to access your saved Sparks.",
        },
      },
    ]);

    return {
      guidedTourContentRef,
      handleFinishTour,
      steps,
    };
  },
});
