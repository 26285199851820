
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useTrackingStore } from "@/stores/tracking";
import { useSparksStore } from "@/stores/sparks";
import { useProblemStore } from "@/stores/problem";
import { useTopicsStore } from "@/stores/topics";
import { useMessagesStore } from "@/stores/messages";
import { useProblemSettingsStore } from "@/stores/problemSettings";
import { themes } from "@/constants/problemSettings";
import type { Theme } from "@/types/problemSettings";
import AppButton from "@/components/ui/app-button/AppButton.vue";

export default defineComponent({
  components: {
    AppButton,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const trackingStore = useTrackingStore();
    const sparksStore = useSparksStore();
    const problemStore = useProblemStore();
    const topicsStore = useTopicsStore();
    const messagesStore = useMessagesStore();
    const problemSettingsStore = useProblemSettingsStore();

    const { isShownSparksSection } = storeToRefs(sparksStore);
    const { exampleText, isExampleProblem } = storeToRefs(problemStore);
    const { theme } = storeToRefs(problemSettingsStore);

    const selectedTheme = ref(theme.value);

    const setSelectedTheme = (theme: Theme) => {
      selectedTheme.value = theme;
    };

    // use selected values
    const setProblemSettingsValues = async () => {
      // note the original theme before updating the store
      const originalTheme = theme.value;

      problemSettingsStore.updateSettings({
        theme: selectedTheme.value,
      });

      props.closeModal();

      // if theme changed, then...
      if (selectedTheme.value !== originalTheme) {
        // update example text if it is an example
        if (isExampleProblem.value) {
          problemStore.setProblem(exampleText.value);

          // the possible ideas service requires a short problem summary
          // (this runs in the background, ready for when it is needed)
          problemStore.getProblemSummary();
        }
        // regenerate sparks if the sparks section is already open
        if (isShownSparksSection.value) {
          sparksStore.setShownSparksSection(false);
          sparksStore.clearSparks();

          const hasTopics = await topicsStore.getTopics();
          if (hasTopics) {
            topicsStore.refreshTopics();
            sparksStore.setShownSparksSection(true);
          } else {
            messagesStore.addMessage("info", "NO_EXTRACTED_TOPICS_WORDS");
          }
        }
      }

      trackingStore.trackEvent({ action: "PROBLEM_SETTINGS_MODAL_UPDATE" });
    };

    return {
      themes,
      selectedTheme,
      setSelectedTheme,
      setProblemSettingsValues,
    };
  },
});
