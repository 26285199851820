import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "hidden md:flex flex-row border-b border-cebai-grey-light text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.name,
          class: _normalizeClass({ 'md:mr-8': index !== _ctx.links.length - 1 })
        }, [
          _createVNode(_component_router_link, {
            to: { name: link.name },
            class: "block text-cebai-base-light border-b-2 border-transparent pb-0.5",
            "exact-active-class": "border-white"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link.label), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ], 2))
      }), 128))
    ])
  ]))
}