
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useCookiesStore } from "@/stores/cookies";
import { useTrackingStore } from "@/stores/tracking";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    AppButton,
    AppButtonRouterLink,
    Editorial,
  },
  setup() {
    const route = useRoute();
    const cookiesStore = useCookiesStore();
    const trackingStore = useTrackingStore();

    const { isCookieBannerDismissed } = storeToRefs(cookiesStore);

    // use selected values
    const acceptAllCookies = () => {
      cookiesStore.acceptAllCookies();
      trackingStore.trackPageView({ page_location: route.fullPath });
    };

    return {
      isCookieBannerDismissed,
      acceptAllCookies,
    };
  },
});
