
import { defineComponent, inject } from "vue";
import type { UpdateActiveTab } from "@/types/tabs";

export default defineComponent({
  components: {},
  props: {
    slug: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: "",
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const activeTab = inject<string>("activeTab");
    const updateActiveTab = inject<UpdateActiveTab>("updateActiveTab");

    const handleTabClick = () => {
      if (!updateActiveTab) return;
      updateActiveTab(props.slug);
    };

    return {
      activeTab,
      handleTabClick,
    };
  },
});
