
import { defineComponent } from "vue";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    // Customise styles for multiselect inputs
    // For full options, see:
    // https://github.com/vueform/multiselect#using-classes-prop
    const inputClasses = {
      container:
        "relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border bg-cebai-base-light border border-cebai-grey-mid text-cebai-grey-dark text-base rounded leading-snug outline-none py-2",
      containerActive: "ring ring-offset-cebai-primary-default ring-opacity-30",
      search:
        "w-full absolute inset-0 outline-none appearance-none outline-none box-border border-0 text-base font-bold font-sans bg-cebai-base-light rounded pt-2 pl-3.5",
      tagsSearch:
        "absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-bold font-sans box-border w-full bg-cebai-base-light",
      placeholder:
        "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-cebai-grey-light",
      groupLabel:
        "flex text-xs uppercase items-center justify-start text-left py-3 px-3 cursor-default leading-normal bg-cebai-grey-light text-cebai-grey-dark",
      option:
        "flex items-center justify-start box-border text-left text-cebai-grey-dark cursor-pointer text-base leading-snug py-2 px-3",
      optionSelected: "bg-cebai-primary-default text-cebai-base-light",
      optionPointed: "bg-cebai-grey-light text-cebai-grey-dark",
      optionSelectedPointed: "bg-cebai-primary-default text-cebai-base-light",
      tag: "bg-cebai-primary-default text-cebai-base-light text-sm font-latoSemiBold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center",
      dropdown:
        "max-h-60 absolute -left-px -right-px bottom-0 translate-y-full border border-cebai-grey-mid -mt-px overflow-y-scroll z-50 bg-cebai-base-light text-cebai-grey-mid flex flex-col rounded-b",
      dropdownHidden: "hidden",
    };

    return {
      inputClasses,
    };
  },
});
