import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import type { Spark, SparksApiPayload } from "@/types/sparks";

/* helpers */

export const buildSparksSet = (
  sparks: Record<string, Spark>,
  type: string,
): Spark[] => {
  const sparksList: Spark[] = [];
  Object.keys(sparks).forEach((key) => {
    // randomise guidance examples (UI shows the first one out of 3)
    sparks[key].guidances = sparks[key].guidances
      ? sparks[key].guidances.sort(() => 0.5 - Math.random())
      : [];
    sparks[key].origin = type;
    sparksList.push(sparks[key]);
  });
  return sparksList;
};

/* api calls */

export const getSparksApi = (
  sparkTheme: string,
  sparkType: string,
  payload: SparksApiPayload,
): AxiosPromise => {
  return apiClient.post(`/sparks/${sparkTheme}/${sparkType}`, payload);
};
