
import { defineComponent, computed, watch, ref } from "vue";
import { storeToRefs } from "pinia";
import { useSavedSparksStore } from "@/stores/savedSparks";
import { useTrackingStore } from "@/stores/tracking";
import { copyToClipboard } from "@/utils/download";
import type { Spark } from "@/types/sparks";
import SparkComponent from "@/components/content/spark/Spark.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import CloseIcon from "@/assets/images/icon-close.svg";
import SavedSparksBg from "@/assets/images/img-saved-sparks.svg";

export default defineComponent({
  components: {
    SparkComponent,
    AppButton,
    Editorial,
    LoadingIcon,
    CloseIcon,
    SavedSparksBg,
  },
  setup() {
    const savedSparksStore = useSavedSparksStore();
    const trackingStore = useTrackingStore();

    const { isShown, isLoading, savedSparks, sparkCount } =
      storeToRefs(savedSparksStore);

    const containerEl = ref<HTMLElement | null>(null);
    const sparksToRemove = ref<Spark[]>([]);
    const selectedSparks = computed(() =>
      savedSparks.value.filter(
        (spark) => !sparksToRemove.value.includes(spark),
      ),
    );

    const hideSparksDrawer = () => {
      savedSparksStore.setShownSparksDrawer(false);

      sparksToRemove.value.forEach((spark) => {
        savedSparksStore.removeSavedSpark(spark);
      });

      // clear list of sparks to remove
      sparksToRemove.value = [];
    };

    const handleSparkClick = (spark: Spark) => {
      if (sparksToRemove.value.includes(spark)) {
        const index = sparksToRemove.value.findIndex(
          (sparkToRemove) => sparkToRemove === spark,
        );
        sparksToRemove.value.splice(index, 1);

        trackingStore.trackEvent({ action: "SPARKS_DRAWER_REMOVE" });
      } else {
        sparksToRemove.value.push(spark);

        trackingStore.trackEvent({ action: "SPARKS_DRAWER_ADD" });
      }
    };

    const handleCopyToClipboard = async () => {
      if (!savedSparks.value) return;
      await copyToClipboard(selectedSparks.value);

      trackingStore.trackEvent({ action: "SPARKS_DRAWER_DOWNLOAD" });
    };

    watch(isShown, () => {
      if (isShown.value) {
        // scroll to the top when first opening the drawer
        savedSparksStore.getSavedSparks();
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        document.body.classList.add("is-fixed");

        // wait for the open draw animation to complete before setting focus
        // this enables keyboard escape, and keyboard navigation
        containerEl.value?.addEventListener(
          "transitionend",
          () => {
            containerEl.value?.focus();
          },
          { once: true },
        );
      } else {
        document.body.classList.remove("is-fixed");
      }
    });

    return {
      isShown,
      isLoading,
      savedSparks,
      sparkCount,
      containerEl,
      sparksToRemove,
      selectedSparks,
      hideSparksDrawer,
      handleSparkClick,
      handleCopyToClipboard,
    };
  },
});
