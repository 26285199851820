
import { defineComponent, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMessagesStore } from "@/stores/messages";
import { useToast } from "vue-toastification";
import ToastContent from "@/components/ui/toast-content/ToastContent.vue";

export default defineComponent({
  components: {},
  setup() {
    const messagesStore = useMessagesStore();
    const toast = useToast();

    const { latestMessage } = storeToRefs(messagesStore);

    watch(latestMessage, () => {
      if (latestMessage.value) {
        const content = {
          component: ToastContent,
          props: {
            title: latestMessage.value.content?.title,
            body: latestMessage.value.content?.body,
          },
        };
        toast[latestMessage.value.type](content);
      }
    });

    return {};
  },
});
