
import { defineComponent, PropType, computed, reactive } from "vue";
import { useTrackingStore } from "@/stores/tracking";
import { useSavedSparksStore } from "@/stores/savedSparks";
import { usePossibleIdeasStore } from "@/stores/possibleIdeas";
import { useProblemStore } from "@/stores/problem";
import { useModal } from "@/composables/useModal";
import type { Spark } from "@/types/sparks";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import PossibleIdeas from "@/components/content/possible-ideas/PossibleIdeas.vue";
import BotButton from "@/components/ui/bot-button/BotButton.vue";
import BookmarkIcon from "@/assets/images/icon-bookmark.svg";
import BookmarkFilledIcon from "@/assets/images/icon-bookmark-filled.svg";

export default defineComponent({
  components: {
    ModalContent,
    PossibleIdeas,
    BotButton,
    BookmarkIcon,
    BookmarkFilledIcon,
  },
  props: {
    spark: {
      type: Object as PropType<Spark>,
      required: true,
    },
    isSaved: {
      type: Boolean,
      required: true,
    },
    handleSparkClick: {
      type: Function,
      required: false,
      default: null,
    },
    index: {
      type: String,
      required: true,
    },
    hasBot: {
      type: Boolean,
      required: false,
      default: false,
    },
    method: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const trackingStore = useTrackingStore();
    const savedSparksStore = useSavedSparksStore();
    const possibleIdeasStore = usePossibleIdeasStore();
    const problemStore = useProblemStore();

    const hasGuidances = computed(() => props.spark.guidances.length > 0);

    // the possible ideas endpoint needs a problem summary of 10 or more chars
    // else, use the full problem text (to be summarised in backend instead)
    const problem = computed(() =>
      problemStore.problemSummary.length >= 10
        ? problemStore.problemSummary
        : problemStore.problem,
    );
    const fullSpark = hasGuidances.value
      ? props.spark.text + " " + props.spark.guidances[0]
      : props.spark.text;

    const modalState = reactive({ possibleIdeasModal: false });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const handleBookmarkClick = () => {
      if (props.handleSparkClick) {
        props.handleSparkClick(props.spark);
      } else {
        if (props.isSaved) {
          savedSparksStore.removeSavedSpark(props.spark);

          trackingStore.trackEvent({
            action: props.method
              ? `SPARK_${props.method.toUpperCase()}_REMOVE`
              : "SPARK_REMOVE",
          });
        } else {
          savedSparksStore.addSavedSpark(props.spark);

          trackingStore.trackEvent({
            action: props.method
              ? `SPARK_${props.method.toUpperCase()}_ADD`
              : "SPARK_ADD",
          });
        }
      }
    };

    const handleBotClick = () => {
      possibleIdeasStore.getPossibleIdeas({
        problem: problem.value,
        spark: fullSpark,
        technique: props.spark.origin,
      });
      showModal("possibleIdeasModal");

      trackingStore.trackEvent({
        action: props.method
          ? `POSSIBLE_IDEAS_${props.method.toUpperCase()}_SHOW`
          : "POSSIBLE_IDEAS_SHOW",
      });
    };

    return {
      hasGuidances,
      problem,
      isShown,
      closeModal,
      handleBookmarkClick,
      handleBotClick,
    };
  },
});
