
import { defineComponent, computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { AxiosProgressEvent } from "axios";
import { useTrackingStore } from "@/stores/tracking";
import { useEventListener } from "@/composables/useEventListener";
import { useSparksStore } from "@/stores/sparks";
import { useMessagesStore } from "@/stores/messages";
import { useProblemStore } from "@/stores/problem";
import { useTopicsStore } from "@/stores/topics";
import { maxFileSizeForProblem } from "@/constants/problem";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import ArrowIcon from "@/assets/images/icon-arrow.svg";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    AppButton,
    ArrowIcon,
    LoadingIcon,
  },
  emits: ["submitted"],
  setup(_props, { emit }) {
    const trackingStore = useTrackingStore();
    const sparksStore = useSparksStore();
    const messagesStore = useMessagesStore();
    const problemStore = useProblemStore();
    const topicsStore = useTopicsStore();

    const { isLoadingProblem } = storeToRefs(problemStore);
    const { isLoadingTopics } = storeToRefs(topicsStore);

    const isSubmitDisabled = computed(
      () =>
        !problemText.value || isLoadingProblem.value || isLoadingTopics.value,
    );

    const uploadPercentage = ref<number | null>(null);

    // dropzone - loosely based on:
    // https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    const dropzoneEl = ref<HTMLElement | null>(null);
    const fileInputEl = ref<HTMLInputElement | null>(null);
    const fileFormEl = ref<HTMLFormElement | null>(null);

    const file = ref<File | null>(null);
    const problemText = ref<string | null>(null);

    const isHovered = ref(false);

    const handleDropzoneClick = () => {
      fileInputEl.value?.click();
    };

    const preventDefaults = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = () => {
      isHovered.value = true;
    };

    const unhighlight = () => {
      isHovered.value = false;
    };

    const handleFileDropped = (e: Event) => {
      clearFile();

      // type cast specifically as a drag event to access the data
      const dt = (e as DragEvent).dataTransfer;
      const droppedFiles = dt?.files;

      if (droppedFiles && fileInputEl.value?.files) {
        fileInputEl.value.files = droppedFiles;
        prepareFileForUpload(
          fileInputEl.value.files[fileInputEl.value.files.length - 1],
        );
      }
    };

    const handleUploadFile = () => {
      clearFile();
      if (fileInputEl.value?.files) {
        const files = Array.from(fileInputEl.value.files);
        if (files.length) {
          prepareFileForUpload(files[files.length - 1]);
        }
      }
    };

    const prepareFileForUpload = (selectedFile: File) => {
      if (selectedFile.type !== "application/pdf") {
        messagesStore.addMessage("error", "UPLOAD_NOT_PDF");
        return;
      }

      // alert the user if their file size is too large
      if (selectedFile.size > maxFileSizeForProblem) {
        messagesStore.addMessage("error", "UPLOAD_FILE_TOO_LARGE");
        return;
      }

      // checks passed, begin file upload
      uploadFile(selectedFile);
    };

    const uploadFile = async (selectedFile: File) => {
      file.value = selectedFile;
      uploadPercentage.value = 0;

      const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.progress) {
          uploadPercentage.value = Math.round(progressEvent.progress * 100);
        } else {
          uploadPercentage.value = null;
        }
      };

      const formData = new FormData();
      formData.append("file", file.value);

      const text = await problemStore.getProblemTextFromPdf({
        formData,
        onUploadProgress,
      });

      if (text) {
        problemText.value = text;
      } else {
        problemText.value = null;
      }

      trackingStore.trackEvent({ action: "PROBLEM_FORM_FILE_UPLOAD" });
    };

    const clearFile = () => {
      file.value = null;
    };

    const clearPDF = () => {
      clearFile();
      fileFormEl.value?.reset();
    };

    const clearAll = () => {
      clearPDF();
      sparksStore.setShownSparksSection(false);

      // clear out state from the store
      problemStore.clearProblem();
      problemStore.clearProblemSummary();
      sparksStore.clearSparks();
      topicsStore.clearTopics();
    };

    const handleSubmit = async () => {
      if (!problemText.value) return;

      sparksStore.setShownSparksSection(false);
      sparksStore.clearSparks();

      // tell parent that pdf has been submitted
      emit("submitted", "pdf");

      problemStore.setProblem(problemText.value);

      // the possible ideas service requires a short problem summary
      // (this runs in the background, ready for when it is needed)
      problemStore.getProblemSummary();

      const hasTopics = await topicsStore.getTopics();
      if (hasTopics) {
        topicsStore.refreshTopics();
        sparksStore.setShownSparksSection(true);
      } else {
        messagesStore.addMessage("info", "NO_EXTRACTED_TOPICS_PDF");
      }

      trackingStore.trackEvent({ action: "PROBLEM_FORM_UPLOAD_SUBMIT" });
    };

    // Prevent defaults
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      useEventListener(dropzoneEl, eventName, preventDefaults);
    });

    // Highlight dropzone on drag enter/over
    ["dragenter", "dragover"].forEach((eventName) => {
      useEventListener(dropzoneEl, eventName, highlight);
    });

    // Remove highlight on drag leave/drop
    ["dragleave", "drop"].forEach((eventName) => {
      useEventListener(dropzoneEl, eventName, unhighlight);
    });

    // handle drop
    useEventListener(dropzoneEl, "drop", handleFileDropped);

    // handle upload
    useEventListener(fileInputEl, "change", handleUploadFile);

    return {
      file,
      uploadPercentage,
      isHovered,
      dropzoneEl,
      fileInputEl,
      fileFormEl,
      handleDropzoneClick,
      isSubmitDisabled,
      isLoadingProblem,
      isLoadingTopics,
      clearFile,
      clearPDF,
      clearAll,
      handleSubmit,
    };
  },
});
