import type { SparksTab } from "@/types/sparks";

/**
 * The definitions of the sparks tabs. The label is the text displayed on the tab in
 * the ui. The slug property is the 'method' used for the types in the sparks api calls
 * @constant
 */
export const sparksTabs: SparksTab[] = [
  {
    slug: "trigger",
    label: "Creativity triggers",
  },
  {
    slug: "constraint",
    label: "Constraint removal",
  },
  {
    slug: "scamper",
    label: "Different perspectives",
  },
  {
    slug: "bf",
    label: "Backwards-forwards",
  },
  {
    slug: "combinations",
    label: "Combinations",
  },
];
