
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  nextTick,
  watch,
} from "vue";
import { useGuidedTourStore } from "@/stores/guidedTour";
import { sparksTabs } from "@/constants/sparks";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import TabContent from "@/components/ui/tabs/TabContent.vue";
import GeneratedSparksTab from "@/components/content/generated-sparks/GeneratedSparksTab.vue";

export default defineComponent({
  components: {
    Editorial,
    Tabs,
    TabContent,
    GeneratedSparksTab,
  },
  setup() {
    const guidedTourStore = useGuidedTourStore();

    const tabsRef = ref<InstanceType<typeof Tabs> | null>(null);
    const el = ref<HTMLDivElement | null>(null);

    const activeTab = computed(
      () => tabsRef.value?.getActiveTab() || sparksTabs[0].slug,
    );

    watch(activeTab, () => {
      guidedTourStore.setActiveSparkTab(activeTab.value);
    });

    const scrollPageTo = async () => {
      // wait for the DOM updates to complete
      await nextTick();

      // scroll to the Generated Sparks section
      if (el.value !== null) {
        const element = el.value;
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    onMounted(() => {
      scrollPageTo();
    });

    return {
      sparksTabs,
      tabsRef,
      el,
      activeTab,
    };
  },
});
