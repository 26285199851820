import { ref, reactive, computed } from "vue";
import { defineStore } from "pinia";
import { useTrackingStore } from "./tracking";
import type { CookieState, CookieType, Cookies } from "@/types/cookies";

/* Define the store */

export const useCookiesStore = defineStore(
  "cookies",
  () => {
    /**
     * Nested stores
     */
    const trackingStore = useTrackingStore();

    /**
     * State
     */
    const isCookieBannerDismissed = ref<boolean>(false);
    const cookiesState = reactive<Cookies>({
      preferences: null,
      statistics: null,
      marketing: null,
    });

    /**
     * Getters
     */
    const hasTrackingAvailable = computed<boolean>(
      () => !!process.env.VUE_APP_ANALYTICS_ID,
    );

    const preferencesCookies = computed<boolean>({
      get: () => {
        // As the cookie could be null, we convert it to a boolean
        return !!cookiesState.preferences;
      },
      set: (value) => {
        setCookieValue("preferences", value);
      },
    });

    const statisticsCookies = computed<boolean>({
      get() {
        // As the cookie could be null, we convert it to a boolean
        return !!cookiesState.statistics;
      },
      set(value) {
        setCookieValue("statistics", value);
      },
    });

    const marketingCookies = computed<boolean>({
      get() {
        // As the cookie could be null, we convert it to a boolean
        return !!cookiesState.marketing;
      },
      set(value) {
        setCookieValue("marketing", value);
      },
    });

    /**
     * Actions
     */
    function setCookieValue(type: CookieType, value: CookieState): void {
      cookiesState[type] = value;
      if (type === "statistics") {
        trackingStore.updateTrackingState();
      }
      trackingStore.trackEvent({
        action: `SET_${type.toUpperCase()}_COOKIE_TO_${value
          ?.toString()
          .toUpperCase()}`,
      });
    }

    function dismissCookieBanner(): void {
      isCookieBannerDismissed.value = true;
    }

    function acceptAllCookies(): void {
      dismissCookieBanner();
      Object.keys(cookiesState).forEach((key) => {
        cookiesState[key] = true;
      });
    }

    return {
      isCookieBannerDismissed,
      cookiesState,
      hasTrackingAvailable,
      preferencesCookies,
      statisticsCookies,
      marketingCookies,
      setCookieValue,
      dismissCookieBanner,
      acceptAllCookies,
    };
  },
  {
    // persist cookies store state in local storage
    persist: true,
  },
);
