
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const links = [
      {
        title: "Email us",
        url: "mailto:info@designsparks.io",
      },
      {
        title: "Tech support",
        url: "https://creativityai.atlassian.net/servicedesk/customer/portal/4",
      },
      {
        title: "Terms of use",
        url: "https://www.designsparks.io/terms-of-use-policy",
      },
      {
        title: "Privacy policy",
        url: "https://www.designsparks.io/privacy-policy",
      },
      {
        title: "Customer contact policy",
        url: "https://www.designsparks.io/customer-contact-policy",
      },
      {
        title: "Cookie policy",
        name: "cookie-policy",
        internal: true,
      },
    ];

    const year = new Date().getFullYear();

    return {
      links,
      year,
    };
  },
});
