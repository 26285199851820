
import { defineComponent, ref, PropType } from "vue";
import { storeToRefs } from "pinia";
import { useTrackingStore } from "@/stores/tracking";
import { useTopicsStore } from "@/stores/topics";
import { useSparksStore } from "@/stores/sparks";
import { useTopicsSettingsStore } from "@/stores/topicsSettings";
import { sortTypes, maxTopicsValues } from "@/constants/topicsSettings";
import type { SparkType } from "@/types/sparks";
import type { SortType } from "@/types/topicsSettings";
import AppButton from "@/components/ui/app-button/AppButton.vue";
// import Toggle from "@/components/ui/toggle/Toggle.vue";

export default defineComponent({
  components: {
    AppButton,
    // Toggle,
  },
  props: {
    method: {
      type: String as PropType<SparkType>,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const trackingStore = useTrackingStore();
    const topicSettingsStore = useTopicsSettingsStore();
    const topicsStore = useTopicsStore();
    const sparksStore = useSparksStore();

    const { sortType, maxTopics, enhanceText } =
      storeToRefs(topicSettingsStore);

    const selectedSortType = ref(sortType.value);
    const setSelectedSortType = (sortType: SortType) => {
      selectedSortType.value = sortType;
    };

    const selectedMaxTopics = ref(maxTopics.value);
    const setSelectedMaxTopics = (maxTopics: number) => {
      selectedMaxTopics.value = maxTopics;
    };

    const selectedEnhanceText = ref(enhanceText.value);

    // use selected values
    const setTopicSettingsValues = () => {
      topicSettingsStore.updateSettings({
        sortType: selectedSortType.value,
        maxTopics: selectedMaxTopics.value,
        enhanceText: selectedEnhanceText.value,
      });

      topicsStore.refreshTopics();
      sparksStore.getSparks({ type: props.method, clearAllSparks: true });

      trackingStore.trackEvent({ action: "TOPICS_MODAL_UPDATE" });

      props.closeModal();
    };

    return {
      sortTypes,
      selectedSortType,
      setSelectedSortType,
      maxTopicsValues,
      maxTopics,
      selectedMaxTopics,
      setSelectedMaxTopics,
      setTopicSettingsValues,
    };
  },
});
