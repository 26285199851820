import type { HeaderLink } from "@/types/menu";

/**
 * The definitions of the menu tabs. The label property is the text displayed
 * on the tab in the ui. The name property is used for the router links.
 * @constant
 */
export const navLinks: HeaderLink[] = [
  {
    name: "home",
    label: "Home",
  },
  {
    name: "about",
    label: "About",
  },
  {
    name: "techniques",
    label: "Techniques",
  },
];
