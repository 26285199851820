
import { defineComponent, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useCookiesStore } from "@/stores/cookies";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Toggle from "@/components/ui/toggle/Toggle.vue";

export default defineComponent({
  components: {
    AppContent,
    AppButton,
    Editorial,
    Toggle,
  },
  setup() {
    const router = useRouter();
    const cookiesStore = useCookiesStore();

    const { preferencesCookies, statisticsCookies, marketingCookies } =
      storeToRefs(cookiesStore);

    // cast history state to a string so that we can use it in path
    // if no back history, use home
    const continueLink = computed(() =>
      router.options.history.state.back
        ? String(router.options.history.state.back)
        : "/",
    );

    const handleContinue = () => {
      router.push({
        path: continueLink.value,
      });
    };

    onMounted(() => {
      cookiesStore.dismissCookieBanner();
    });

    return {
      preferencesCookies,
      statisticsCookies,
      marketingCookies,
      handleContinue,
    };
  },
});
