import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import type { PossibleIdeasPayload } from "@/types/possibleIdeas";

/* api calls */

// problem summary (required by the possible ideas endpoint)
let summaryController: AbortController | undefined;

export const getProblemSummaryApi = (text: string): AxiosPromise => {
  // check if AbortController is already assigned.
  // if true, call abort() to cancel the pending request
  if (summaryController) {
    summaryController.abort();
    summaryController = undefined;
  }
  // create a new instance of the AbortController before making API request
  summaryController = new AbortController();

  // reference to the AbortSignal object via the AbortController.signal property
  const signal = summaryController.signal;

  return apiClient.post(`/nlp/summary`, { text }, { signal });
};

// possible ideas
let ideasController: AbortController | undefined;

export const getPossibleIdeasApi = (
  payload: PossibleIdeasPayload,
): AxiosPromise => {
  // check if AbortController is already assigned.
  // if true, call abort() to cancel the pending request
  if (ideasController) {
    ideasController.abort();
    ideasController = undefined;
  }
  // create a new instance of the AbortController before making API request
  ideasController = new AbortController();

  // reference to the AbortSignal object via the AbortController.signal property
  const signal = ideasController.signal;

  return apiClient.post(`/nlp/possible-ideas`, payload, { signal });
};
