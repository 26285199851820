import { ref } from "vue";
import { defineStore } from "pinia";
import type { Theme, ProblemSettings } from "@/types/problemSettings";

/* Define the store */

export const useProblemSettingsStore = defineStore("problemSetings", () => {
  /**
   * State
   */
  const theme = ref<Theme>("general");

  /**
   * Actions
   */
  function updateSettings(settings: ProblemSettings): void {
    theme.value = settings.theme;
  }

  return {
    theme,
    updateSettings,
  };
});
