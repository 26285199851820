import { ref } from "vue";
import { defineStore } from "pinia";
import { useMessagesStore } from "./messages";
import { useProblemStore } from "./problem";
import { captureException } from "@/utils/errors";
import { waitUntil } from "@/utils/timeouts";
import { getPossibleIdeasApi } from "@/services/possible-ideas.service";
import type { PossibleIdea, PossibleIdeasPayload } from "@/types/possibleIdeas";
import type { Spark } from "@/types/sparks";
import axios from "axios";

/* Define the store */

export const usePossibleIdeasStore = defineStore("possibleIdeas", () => {
  /**
   * Nested stores
   */
  const messagesStore = useMessagesStore();
  const problemStore = useProblemStore();

  /**
   * State
   */
  const ideas = ref<Spark[]>([]);
  const isLoading = ref<boolean>(false);
  const hasError = ref<boolean>(false);
  const hasTimedOut = ref<boolean>(false);

  /**
   * Actions
   */
  function setPossibleIdeas(newIdeas: Spark[]): void {
    ideas.value = newIdeas;
  }

  function clearPossibleIdeas(): void {
    ideas.value = [];
  }

  async function getPossibleIdeas(
    payload: PossibleIdeasPayload,
  ): Promise<void> {
    isLoading.value = true;
    hasTimedOut.value = false;
    hasError.value = false;
    clearPossibleIdeas();

    // if there is no problem summary, wait for it for a number of attempts
    if (!problemStore.hasProblemSummary) {
      const hasSummary = await waitUntil(() => problemStore.hasProblemSummary);
      // update problem to problemSummary if it exists in time
      if (hasSummary) {
        payload.problem = problemStore.problemSummary;
      }
    }

    return getPossibleIdeasApi(payload)
      .then((response) => {
        const ideas = response.data.results;
        const ideasList: Spark[] = [];

        ideas.forEach((item: PossibleIdea) => {
          const idea = {
            text: item.text,
            origin: "openAI",
            guidances: [],
          };
          ideasList.push(idea);
        });

        setPossibleIdeas(ideasList);
        isLoading.value = false;
      })
      .catch((error) => {
        // ignore if failure is a cancellation (loading continues)
        if (!axios.isCancel(error)) {
          // capture timeout so we can display a specific message
          if (error.code === "ECONNABORTED") {
            hasTimedOut.value = true;
          } else {
            hasError.value = true;
          }
          captureException(error);
          messagesStore.addMessage("error", "ERROR");
          isLoading.value = false;
        }
      });
  }

  return {
    ideas,
    isLoading,
    hasError,
    hasTimedOut,
    getPossibleIdeas,
  };
});
