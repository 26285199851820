import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useMessagesStore } from "./messages";
import { useProblemStore } from "./problem";
import { useTopicsSettingsStore } from "./topicsSettings";
import { buildTopicsSet, getTopicsApi } from "@/services/topics.service";
import { extractEntityType, getEntityType } from "@/services/entities.service";
import { captureException } from "@/utils/errors";
import type { SortType } from "@/types/topicsSettings";
import type { Topic, Entity } from "@/types/topics";

/* Define the store */

export const useTopicsStore = defineStore("topics", () => {
  /**
   * Nested stores
   */
  const messagesStore = useMessagesStore();
  const problemStore = useProblemStore();
  const topicSettingsStore = useTopicsSettingsStore();

  /**
   * State
   */
  const isExpanded = ref<boolean>(false);
  const isLoadingTopic = ref<boolean>(false);
  const isLoadingTopics = ref<boolean>(false);
  const hasErrorTopics = ref<boolean>(false);
  const topics = ref<Topic[]>([]);
  const extractedTopics = ref<Topic[]>([]);

  /**
   * Getters
   */
  const topicsCount = computed<number>(() => topics.value.length);

  const activeTopicsCount = computed<number>(
    () => topics.value.filter((topic) => topic.active).length,
  );

  const activeTopicsSummary = computed<string>(() => {
    return topics.value
      .flatMap((topic) => (topic.active ? [topic.label] : []))
      .join(", ");
  });

  // the sparks service requires entities (they have fewer properties than topics)
  const activeTopicsAsEntities = computed<Entity[]>(() => {
    return topics.value
      .filter((topic) => topic.active)
      .map(({ label, type, count }) => ({
        label,
        type,
        count,
      }));
  });

  /**
   * Actions
   */
  function setExpanded(isExpandedTopicsSection: boolean): void {
    isExpanded.value = isExpandedTopicsSection;
  }

  function setTopics(newTopics: Topic[]): void {
    topics.value = newTopics;
  }

  function clearTopics(): void {
    topics.value = [];
  }

  function setExtractedTopics(newExtractedTopics: Topic[]): void {
    extractedTopics.value = newExtractedTopics;
  }

  function addTopic(newTopic: Topic): void {
    topics.value.push(newTopic);
  }

  function selectTopic(topicId: number): void {
    const index = topics.value.findIndex((topic) => topic.id === topicId);
    if (index === -1) return;
    topics.value[index].active = !topics.value[index].active;
  }

  function resetTopics(): void {
    // filter out api extracted topics (i.e. not user added) and set them all to active
    topics.value = topics.value
      .filter((topic) => topic.origin === "api")
      .map((topic) => ({ ...topic, active: true }));
  }

  function sortExtractedTopics(type: SortType): Topic[] {
    const sortedTopics = extractedTopics.value;
    switch (type) {
      case "rare":
        sortedTopics.sort((a, b) => {
          return a.count - b.count;
        });
        break;
      case "random":
        sortedTopics.sort(() => 0.5 - Math.random());
        break;
      default: // "top"
        sortedTopics.sort((a, b) => {
          return b.count - a.count;
        });
        break;
    }
    return sortedTopics;
  }

  function refreshTopics(): void {
    clearTopics();

    // get sorted extracted topics (using sort from topic settings)
    const sortType: SortType = topicSettingsStore.sortType;
    const sortedExtractedTopics = sortExtractedTopics(sortType);

    // apply max topics setting
    const maxTopics: number =
      topicSettingsStore.maxTopics > sortedExtractedTopics.length
        ? sortedExtractedTopics.length
        : topicSettingsStore.maxTopics;

    const topics: Topic[] = sortedExtractedTopics.slice(0, maxTopics);
    setTopics(topics);
  }

  async function getTopics(): Promise<boolean | void> {
    isLoadingTopics.value = true;
    hasErrorTopics.value = false;
    clearTopics();

    return getTopicsApi(problemStore.problem)
      .then((response) => {
        // preserve full set of extracted topics
        const extractedTopics: Topic[] = buildTopicsSet(response.data);
        setExtractedTopics(extractedTopics);

        // return a boolean - were any topics extracted?
        return new Promise<boolean>((resolve) => {
          resolve(extractedTopics.length > 0);
        });
      })
      .catch((error) => {
        captureException(error);
        hasErrorTopics.value = true;
        messagesStore.addMessage("error", "ERROR");
      })
      .finally(() => {
        isLoadingTopics.value = false;
      });
  }

  async function addUserTopic(payload: string): Promise<boolean | void> {
    isLoadingTopic.value = true;

    return getEntityType(payload)
      .then((response) => {
        // extract type from named entity recognition (ner) data
        const entityType = extractEntityType(response.data.Results);

        addTopic({
          id: topicsCount.value + 1,
          label: payload,
          type: entityType,
          count: 1,
          active: true,
          origin: "user",
        });

        return new Promise<boolean>((resolve) => {
          resolve(true);
        });
      })
      .catch((error) => {
        captureException(error);
        hasErrorTopics.value = true;
        messagesStore.addMessage("error", "ERROR");
      })
      .finally(() => {
        isLoadingTopic.value = false;
      });
  }

  return {
    isExpanded,
    isLoadingTopic,
    isLoadingTopics,
    hasErrorTopics,
    topics,
    extractedTopics,
    topicsCount,
    activeTopicsCount,
    activeTopicsSummary,
    activeTopicsAsEntities,
    setExpanded,
    setTopics,
    clearTopics,
    setExtractedTopics,
    addTopic,
    selectTopic,
    resetTopics,
    sortExtractedTopics,
    refreshTopics,
    getTopics,
    addUserTopic,
  };
});
