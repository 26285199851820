import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookmarkFilledIcon = _resolveComponent("BookmarkFilledIcon")!
  const _component_BookmarkIcon = _resolveComponent("BookmarkIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-start border p-3 my-3 rounded-md transition-all duration-300", {
      'border-dashed border-cebai-grey-dark': !_ctx.isSaved,
      'border-cebai-primary-default': _ctx.isSaved,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.idea.text), 1)
    ]),
    _createElementVNode("button", {
      class: "ml-6",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBookmarkClick && _ctx.handleBookmarkClick(...args)))
    }, [
      (_ctx.isSaved)
        ? (_openBlock(), _createBlock(_component_BookmarkFilledIcon, {
            key: 0,
            class: "text-cebai-primary-default fill-current"
          }))
        : (_openBlock(), _createBlock(_component_BookmarkIcon, {
            key: 1,
            class: "text-cebai-primary-default fill-current"
          }))
    ])
  ], 2))
}