
import { defineComponent, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useTrackingStore } from "@/stores/tracking";
import { useMenuStore } from "@/stores/menu";
import { useSavedSparksStore } from "@/stores/savedSparks";
import { useGuidedTourStore } from "@/stores/guidedTour";
import { navLinks } from "@/constants/menu";
import TheHeaderMenu from "./TheHeaderMenu.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import BookmarkIcon from "@/assets/images/icon-bookmark.svg";
import MenuIcon from "@/assets/images/icon-menu.svg";
import Logo from "@/assets/images/logo.svg";

export default defineComponent({
  components: {
    TheHeaderMenu,
    Editorial,
    AppButton,
    BookmarkIcon,
    MenuIcon,
    Logo,
  },
  setup() {
    const route = useRoute();
    const trackingStore = useTrackingStore();
    const menuStore = useMenuStore();
    const savedSparksStore = useSavedSparksStore();
    const guidedTourStore = useGuidedTourStore();

    const { sparkCount } = storeToRefs(savedSparksStore);

    const handleShowSparksDrawer = () => {
      savedSparksStore.setShownSparksDrawer(true);

      trackingStore.trackEvent({ action: "SPARKS_DRAWER_OPEN" });
    };

    const handleShowMobileMenu = () => {
      menuStore.setShownMobileMenu(true);

      trackingStore.trackEvent({ action: "MOBILE_MENU_OPEN" });
    };

    const handleTakeATour = () => {
      guidedTourStore.setStartTour();
    };

    const heading = computed(() => route.meta.heading);
    const subheading = computed(() => route.meta.subheading);
    const hasTour = computed(() => route.meta.hasTour || false);

    return {
      navLinks,
      sparkCount,
      handleShowSparksDrawer,
      handleShowMobileMenu,
      handleTakeATour,
      heading,
      subheading,
      hasTour,
    };
  },
});
