import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex-grow text-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "ml-6 flex flex-col-reverse md:flex-row" }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BotButton = _resolveComponent("BotButton")!
  const _component_BookmarkFilledIcon = _resolveComponent("BookmarkFilledIcon")!
  const _component_BookmarkIcon = _resolveComponent("BookmarkIcon")!
  const _component_PossibleIdeas = _resolveComponent("PossibleIdeas")!
  const _component_ModalContent = _resolveComponent("ModalContent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-start border p-3 my-3 rounded-md transition-all duration-300", {
      'border-dashed border-cebai-grey-mid': !_ctx.isSaved,
      'border-cebai-primary-default': _ctx.isSaved,
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.hasGuidances)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.spark.text) + " " + _toDisplayString(_ctx.spark.guidances[0]), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.spark.text), 1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasBot)
          ? (_openBlock(), _createBlock(_component_BotButton, {
              key: 0,
              id: `button-bot-${_ctx.index}`,
              onClick: _ctx.handleBotClick
            }, null, 8, ["id", "onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          id: `button-bookmark-${_ctx.index}`,
          class: "mb-2 md:ml-3 md:mb-0",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBookmarkClick && _ctx.handleBookmarkClick(...args)))
        }, [
          (_ctx.isSaved)
            ? (_openBlock(), _createBlock(_component_BookmarkFilledIcon, {
                key: 0,
                class: "text-cebai-primary-default fill-current"
              }))
            : (_openBlock(), _createBlock(_component_BookmarkIcon, {
                key: 1,
                class: "text-cebai-primary-default fill-current"
              }))
        ], 8, _hoisted_5)
      ])
    ], 2),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShown.possibleIdeasModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShown.possibleIdeasModal) = $event)),
      close: () => _ctx.closeModal('possibleIdeasModal')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ModalContent, {
          close: () => _ctx.closeModal('possibleIdeasModal'),
          title: "Possible Ideas"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PossibleIdeas, {
              "close-modal": () => _ctx.closeModal('possibleIdeasModal'),
              problem: _ctx.problem,
              spark: _ctx.spark
            }, null, 8, ["close-modal", "problem", "spark"])
          ]),
          _: 1
        }, 8, ["close"])
      ]),
      _: 1
    }, 8, ["modelValue", "close"])
  ], 64))
}