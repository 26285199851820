// Allow the HTML <title> tag to be updated or cleared from anywhere in the app
// Always prepend the name of the site
export const setPageTitle = (title: string) => {
  let pageTitle = process.env.VUE_APP_SITE_TITLE;
  if (title) {
    pageTitle += ` | ${title}`;
  }
  document.title = pageTitle;
};

// Wait for an HTML element to be rendered
// https://stackoverflow.com/a/61511955
export const waitForElement = (selector: string) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};
