
import { defineComponent, ref, ComponentPublicInstance } from "vue";
import {
  useVOnboarding,
  VOnboardingStep,
  VOnboardingWrapper,
} from "v-onboarding";
import type { VOnboardingWrapperOptions } from "v-onboarding/src/types/VOnboardingWrapper";
import GuidedTourStep from "./GuidedTourStep.vue";

export default defineComponent({
  components: {
    VOnboardingStep,
    VOnboardingWrapper,
    GuidedTourStep,
  },
  props: {
    steps: {
      type: Object,
      required: true,
    },
  },
  emits: ["finish"],
  setup(props, { emit }) {
    const wrapper = ref<ComponentPublicInstance<
      typeof VOnboardingWrapper
    > | null>(null);
    // methods include start, finish, goToStep.
    const { start, finish } = useVOnboarding(wrapper);

    const handleFinish = () => {
      finish(); // finish on-boarding
      emit("finish");
    };

    const handleExit = () => {
      finish(); // finish on-boarding
    };

    // default options
    const options: VOnboardingWrapperOptions = {
      popper: {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20],
            },
          },
        ],
      },
    };

    return {
      wrapper,
      handleFinish,
      handleExit,
      start,
      options,
    };
  },
});
