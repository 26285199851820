import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { sleep } from "@/utils/timeouts";
import type { Spark } from "@/types/sparks";

/* Define the store */

export const useSavedSparksStore = defineStore(
  "savedSparks",
  () => {
    /**
     * State
     */
    const isShown = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const savedSparks = ref<Spark[]>([]);

    /**
     * Getters
     */
    const sparkCount = computed<number>(() => savedSparks.value.length);

    /**
     * Actions
     */
    function setShownSparksDrawer(isShownSparksDrawer: boolean): void {
      isShown.value = isShownSparksDrawer;
    }

    function getIsSaved(spark: Spark): boolean {
      return savedSparks.value.includes(spark);
    }

    function addSavedSpark(spark: Spark): void {
      savedSparks.value.unshift(spark);
    }

    function removeSavedSpark(spark: Spark): void {
      const index = savedSparks.value.findIndex(
        (savedSpark) => spark === savedSpark,
      );
      if (index > -1) {
        savedSparks.value.splice(index, 1);
      }
    }

    async function getSavedSparks(): Promise<void> {
      // apply fake loading with a sleep time
      isLoading.value = true;
      await sleep(800);
      isLoading.value = false;
    }

    return {
      isShown,
      isLoading,
      savedSparks,
      sparkCount,
      setShownSparksDrawer,
      getIsSaved,
      addSavedSpark,
      removeSavedSpark,
      getSavedSparks,
    };
  },
  {
    persist: {
      // persist saved sparks only in local storage
      paths: ["savedSparks"],
    },
  },
);
