import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useMessagesStore } from "./messages";
import { useProblemSettingsStore } from "./problemSettings";
import { exampleProblemText, minProblemTextLength } from "@/constants/problem";
import { captureException } from "@/utils/errors";
import { getProblemTextfromUrlApi } from "@/services/url.service";
import { getProblemSummaryApi } from "@/services/possible-ideas.service";
import { getProblemTextfromPdfApi } from "@/services/pdf.service";
import type { PdfPayload } from "@/types/problem";
import axios from "axios";

/* Define the store */

export const useProblemStore = defineStore("problem", () => {
  /**
   * Nested stores
   */
  const messagesStore = useMessagesStore();
  const problemSettingsStore = useProblemSettingsStore();

  /**
   * State
   */
  const problem = ref<string>("");
  const problemSummary = ref<string>("");
  const isLoadingProblem = ref<boolean>(false);

  /**
   * Getters
   */
  const hasProblemText = computed(() => problem.value.length > 0);

  const hasProblemSummary = computed(() => problemSummary.value.length > 0);

  const hasEnoughProblemText = computed(
    () => problem.value.length >= minProblemTextLength,
  );

  const exampleText = computed<string>(
    () => exampleProblemText[problemSettingsStore.theme],
  );

  const isExampleProblem = computed<boolean>(() =>
    Object.values(exampleProblemText).includes(problem.value),
  );

  /**
   * Actions
   */
  function setProblem(newProblem: string): void {
    problem.value = newProblem;
  }

  function clearProblem(): void {
    problem.value = "";
  }

  function setProblemSummary(newProblemSummary: string): void {
    problemSummary.value = newProblemSummary;
  }

  function clearProblemSummary(): void {
    problemSummary.value = "";
  }

  async function getProblemTextFromUrl(
    payload: string,
  ): Promise<string | void> {
    isLoadingProblem.value = true;
    clearProblem();

    return getProblemTextfromUrlApi(payload)
      .then((response) => {
        const problemText = response.data.text;
        if (!problemText) {
          messagesStore.addMessage("info", "NO_EXTRACTED_TEXT_URL");
        }
        return new Promise<string>((resolve) => {
          resolve(problemText);
        });
      })
      .catch((error) => {
        captureException(error);
        messagesStore.addMessage("error", "URL_ERROR");
      })
      .finally(() => {
        isLoadingProblem.value = false;
      });
  }

  async function getProblemTextFromPdf(
    payload: PdfPayload,
  ): Promise<string | void> {
    isLoadingProblem.value = true;
    clearProblem();

    return getProblemTextfromPdfApi(payload.formData, payload.onUploadProgress)
      .then((response) => {
        const problemText = response.data.text;
        if (!problemText) {
          messagesStore.addMessage("info", "NO_EXTRACTED_TEXT_PDF");
        }
        return new Promise<string>((resolve) => {
          resolve(problemText);
        });
      })
      .catch((error) => {
        captureException(error);
        messagesStore.addMessage("error", "PDF_ERROR");
      })
      .finally(() => {
        isLoadingProblem.value = false;
      });
  }

  async function getProblemSummary(): Promise<void> {
    clearProblemSummary();

    return getProblemSummaryApi(problem.value)
      .then((response) => {
        setProblemSummary(response.data.summary);
        isLoadingProblem.value = false;
      })
      .catch((error) => {
        // ignore if failure is a cancellation (loading continues)
        if (!axios.isCancel(error)) {
          // no error toast. Background operation - no need to inform the user
          captureException(error);
          isLoadingProblem.value = false;
        }
      });
  }

  return {
    problem,
    problemSummary,
    isLoadingProblem,
    hasProblemText,
    hasProblemSummary,
    hasEnoughProblemText,
    exampleText,
    isExampleProblem,
    setProblem,
    clearProblem,
    setProblemSummary,
    clearProblemSummary,
    getProblemTextFromUrl,
    getProblemTextFromPdf,
    getProblemSummary,
  };
});
