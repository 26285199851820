
import { defineComponent, PropType } from "vue";
import { storeToRefs } from "pinia";
import { useTrackingStore } from "@/stores/tracking";
import { useSavedSparksStore } from "@/stores/savedSparks";
import { usePossibleIdeasStore } from "@/stores/possibleIdeas";
import type { Spark } from "@/types/sparks";
import PossibleIdea from "@/components/content/possible-ideas/PossibleIdea.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    PossibleIdea,
    AppButton,
    LoadingIcon,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    problem: {
      type: String,
      required: true,
    },
    spark: {
      type: Object as PropType<Spark>,
      required: true,
    },
  },
  setup(props) {
    const trackingStore = useTrackingStore();
    const savedSparksStore = useSavedSparksStore();
    const possibleIdeasStore = usePossibleIdeasStore();

    const { getIsSaved } = savedSparksStore;

    const { ideas, isLoading, hasError, hasTimedOut } =
      storeToRefs(possibleIdeasStore);

    // use selected values
    const refreshIdeas = () => {
      possibleIdeasStore.getPossibleIdeas({
        problem: props.problem,
        spark: props.spark.text,
        technique: props.spark.origin,
      });

      trackingStore.trackEvent({ action: "POSSIBLE_IDEAS_REFRESH" });
    };

    return {
      ideas,
      isLoading,
      hasError,
      hasTimedOut,
      getIsSaved,
      refreshIdeas,
    };
  },
});
