import { defineStore } from "pinia";
import { useCookiesStore } from "./cookies";
import { optIn, optOut, event, pageview, PageView } from "vue-gtag";
import type { TrackEvent } from "@/types/tracking";

/* Define the store */

export const useTrackingStore = defineStore("tracking", () => {
  /**
   * Nested stores
   */
  const cookiesStore = useCookiesStore();

  /**
   * Actions
   */
  function updateTrackingState(): void {
    if (!cookiesStore.hasTrackingAvailable) return;

    // enable/disable analytics, based on statistics cookie value
    if (cookiesStore.cookiesState.statistics) {
      optIn();
    } else {
      optOut();
    }
  }

  function trackPageView(payload: PageView): void {
    if (
      cookiesStore.hasTrackingAvailable &&
      cookiesStore.cookiesState.statistics
    ) {
      pageview(payload);
    }
  }

  function trackEvent(payload: TrackEvent): void {
    if (
      cookiesStore.hasTrackingAvailable &&
      cookiesStore.cookiesState.statistics
    ) {
      event(payload.action, payload.eventParams);
    }
  }

  return {
    updateTrackingState,
    trackPageView,
    trackEvent,
  };
});
