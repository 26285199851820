
import { defineComponent, computed, ref, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useGuidedTourStore } from "@/stores/guidedTour";
import { useMessagesStore } from "@/stores/messages";
import { useProblemStore } from "@/stores/problem";
import { useSparksStore } from "@/stores/sparks";
import { useTopicsStore } from "@/stores/topics";
import { useTrackingStore } from "@/stores/tracking";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import ArrowIcon from "@/assets/images/icon-arrow.svg";

export default defineComponent({
  components: {
    AutoResizeTextarea,
    AppButton,
    ArrowIcon,
  },
  props: {
    selectProblemTab: {
      type: Function,
      required: true,
    },
  },
  emits: ["submitted"],
  setup(props, { emit }) {
    const guidedTourStore = useGuidedTourStore();
    const messagesStore = useMessagesStore();
    const problemStore = useProblemStore();
    const sparksStore = useSparksStore();
    const topicsStore = useTopicsStore();
    const trackingStore = useTrackingStore();

    const { isLoadingTopics } = storeToRefs(topicsStore);
    const { isShownSparksSection } = storeToRefs(sparksStore);
    const { hasProblemText, hasEnoughProblemText, exampleText } =
      storeToRefs(problemStore);
    const { exampleTextAndSubmitSet, startTour, finishTour } =
      storeToRefs(guidedTourStore);

    const problemText = computed({
      get() {
        return problemStore.problem || "";
      },
      set(problemText) {
        problemStore.setProblem(problemText);
      },
    });

    const textareaEl = ref<typeof AutoResizeTextarea | null>(null);
    const showTextarea = ref(problemText.value && problemText.value.length > 0);

    const focusTextarea = async () => {
      await nextTick();
      if (textareaEl.value) {
        textareaEl.value.focus();
      }
    };

    const handleHideTextarea = () => {
      if (problemText.value?.trim().length === 0) {
        showTextarea.value = false;
      }
    };

    const handleShowTextarea = () => {
      showTextarea.value = true;
      focusTextarea();
    };

    const handleSetExample = async () => {
      problemText.value = exampleText.value;
      showTextarea.value = true;

      // after displaying the textarea and adding the example text,
      // automatically scroll the textarea back to the top so we can display
      // the start of the example text
      await nextTick();
      textareaEl.value?.scrollToTop();

      trackingStore.trackEvent({
        action: "PROBLEM_FORM_SET_PROBLEM_AS_EXAMPLE",
      });
    };

    const clearProblemText = () => {
      problemText.value = "";
      showTextarea.value = false;
    };

    const clearAll = () => {
      clearProblemText();
      sparksStore.setShownSparksSection(false);

      // clear out state from the store
      problemStore.clearProblem();
      problemStore.clearProblemSummary();
      sparksStore.clearSparks();
      topicsStore.clearTopics();
    };

    const handleSubmit = async () => {
      sparksStore.setShownSparksSection(false);
      sparksStore.clearSparks();

      // tell parent that text has been submitted
      emit("submitted", "text");

      // the possible ideas service requires a short problem summary
      // (this runs in the background, ready for when it is needed)
      problemStore.getProblemSummary();

      const hasTopics = await topicsStore.getTopics();
      if (hasTopics) {
        topicsStore.refreshTopics();
        sparksStore.setShownSparksSection(true);
      } else {
        messagesStore.addMessage("info", "NO_EXTRACTED_TOPICS_WORDS");
      }

      trackingStore.trackEvent({ action: "PROBLEM_FORM_WORDS_SUBMIT" });
    };

    // Guided tour
    let preTourProblem = "";
    let preTourSparksSection = false;

    watch(startTour, () => {
      if (startTour.value) {
        // capture pre-tour state
        preTourProblem = problemText.value;
        preTourSparksSection = isShownSparksSection.value;

        props.selectProblemTab("tab-words");
      }
    });

    watch(exampleTextAndSubmitSet, async () => {
      if (exampleTextAndSubmitSet.value) {
        handleSetExample();
        await handleSubmit();
        guidedTourStore.setExampleTextAndSubmit(false);
      }
    });

    watch(finishTour, () => {
      if (finishTour.value) {
        // if the sparks section wasn't shown pre-tour, we need to hide it
        // and revert the problem text back to its original state.
        // (the tour doesn't overwrite anything if the sparks section is already shown)
        if (!preTourSparksSection) {
          problemText.value = preTourProblem;
          if (preTourProblem === "") {
            showTextarea.value = false;
          }
          sparksStore.setShownSparksSection(false);
          sparksStore.clearSparks();

          // reset pre-tour variables
          preTourProblem = "";
          preTourSparksSection = false;
        }
      }
    });

    return {
      problemText,
      textareaEl,
      hasProblemText,
      hasEnoughProblemText,
      isLoadingTopics,
      showTextarea,
      clearProblemText,
      clearAll,
      handleSetExample,
      handleSubmit,
      handleShowTextarea,
      handleHideTextarea,
    };
  },
});
