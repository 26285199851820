import { ref, reactive } from "vue";
import { defineStore } from "pinia";
import { useMessagesStore } from "./messages";
import { useProblemStore } from "./problem";
import { useProblemSettingsStore } from "./problemSettings";
import { useTopicsSettingsStore } from "./topicsSettings";
import { useTopicsStore } from "./topics";
import { buildSparksSet, getSparksApi } from "@/services/sparks.service";
import { captureException } from "@/utils/errors";
import type {
  SparkType,
  Spark,
  Sparks,
  GetSparksPayload,
  SparksApiPayload,
} from "@/types/sparks";

/* Define the store */

export const useSparksStore = defineStore("sparks", () => {
  /**
   * Nested stores
   */
  const messagesStore = useMessagesStore();
  const problemStore = useProblemStore();
  const problemSettingsStore = useProblemSettingsStore();
  const topicSettingsStore = useTopicsSettingsStore();
  const topicsStore = useTopicsStore();

  /**
   * State
   */
  const isShownSparksSection = ref<boolean>(false);
  const isLoading = ref<SparkType[]>([]);
  const hasError = ref<SparkType[]>([]);

  const sparks = reactive<Sparks>({
    trigger: [],
    constraint: [],
    scamper: [],
    bf: [],
    combinations: [],
  });

  /**
   * Actions
   */
  function setShownSparksSection(isShown: boolean): void {
    isShownSparksSection.value = isShown;
  }

  function setSparks(sparkType: SparkType, newSparks: Spark[]): void {
    sparks[sparkType] = newSparks;
  }

  function clearSparks(): void {
    Object.keys(sparks).forEach((key) => {
      sparks[key] = [];
    });
  }

  async function getSparks(payload: GetSparksPayload): Promise<void> {
    const sparkTheme = problemSettingsStore.theme;
    const sparkType: SparkType = payload.type;

    isLoading.value.push(sparkType);
    hasError.value.splice(hasError.value.indexOf(sparkType), 1);

    if (payload.clearAllSparks) {
      clearSparks();
    } else {
      setSparks(sparkType, []);
    }

    let apiPayload: SparksApiPayload = {};
    // the backwards-forwards API doesn't use entities.
    // use the problem summary if it exists, otherwise the problem text.
    if (sparkType === "bf") {
      apiPayload = {
        text: problemStore.problemSummary
          ? problemStore.problemSummary
          : problemStore.problem,
        enhance_text: topicSettingsStore.enhanceText,
      };
    } else {
      apiPayload = {
        entities: topicsStore.activeTopicsAsEntities,
        enhance_text: topicSettingsStore.enhanceText,
      };
    }

    return getSparksApi(sparkTheme, sparkType, apiPayload)
      .then((response) => {
        let sparks: Spark[] = [];
        sparks = buildSparksSet(response.data, sparkType);
        setSparks(sparkType, sparks);
      })
      .catch((error) => {
        captureException(error);
        hasError.value.push(sparkType);
        messagesStore.addMessage("error", "ERROR");
      })
      .finally(() => {
        isLoading.value.splice(isLoading.value.indexOf(sparkType), 1);
      });
  }

  return {
    isShownSparksSection,
    isLoading,
    hasError,
    sparks,
    setShownSparksSection,
    clearSparks,
    getSparks,
  };
});
