
import { defineComponent, ref, reactive } from "vue";
import { useTrackingStore } from "@/stores/tracking";
import { useModal } from "@/composables/useModal";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import ProblemFormWords from "./ProblemFormWords.vue";
import ProblemFormUpload from "./ProblemFormUpload.vue";
import ProblemFormUrl from "./ProblemFormUrl.vue";
import ProblemFormSettings from "./ProblemFormSettings.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import TabContent from "@/components/ui/tabs/TabContent.vue";
import PencilIcon from "@/assets/images/icon-pencil.svg";
import DocumentIcon from "@/assets/images/icon-document.svg";
import LinkIcon from "@/assets/images/icon-link.svg";
import ProblemSettingsIcon from "@/assets/images/icon-problem-settings.svg";

export default defineComponent({
  components: {
    ModalContent,
    ProblemFormWords,
    ProblemFormUpload,
    ProblemFormUrl,
    ProblemFormSettings,
    Editorial,
    Tabs,
    Tab,
    TabContent,
    PencilIcon,
    DocumentIcon,
    LinkIcon,
    ProblemSettingsIcon,
  },
  setup() {
    const trackingStore = useTrackingStore();

    const tabsRef = ref<InstanceType<typeof Tabs> | null>(null);
    const selectProblemTab = (slug: string) => {
      tabsRef.value?.updateActiveTab(slug);
    };

    // reference each component so that the other two forms can be cleared on submit
    const problemFormWordsRef = ref<InstanceType<
      typeof ProblemFormWords
    > | null>(null);
    const problemFormUploadRef = ref<InstanceType<
      typeof ProblemFormUpload
    > | null>(null);
    const problemFormUrlRef = ref<InstanceType<typeof ProblemFormUrl> | null>(
      null,
    );

    const clearOtherForms = (type: string) => {
      switch (type) {
        case "text":
          problemFormUploadRef.value?.clearFile();
          problemFormUrlRef.value?.clearUrl();
          break;
        case "pdf":
          problemFormWordsRef.value?.clearProblemText();
          problemFormUrlRef.value?.clearUrl();
          break;
        case "url":
          problemFormWordsRef.value?.clearProblemText();
          problemFormUploadRef.value?.clearFile();
          break;
      }
    };

    const modalState = reactive({ problemSettingsModal: false });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const handleProblemSettingsClick = () => {
      showModal("problemSettingsModal");

      trackingStore.trackEvent({ action: "PROBLEM_SETTINGS_MODAL_SHOW" });
    };

    return {
      tabsRef,
      selectProblemTab,
      problemFormWordsRef,
      problemFormUploadRef,
      problemFormUrlRef,
      clearOtherForms,
      isShown,
      showModal,
      closeModal,
      handleProblemSettingsClick,
    };
  },
});
