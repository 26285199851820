
import { defineComponent } from "vue";
import { useScrollToTop } from "@/composables/useScrollToTop";
import UpIcon from "@/assets/images/icon-up.svg";

export default defineComponent({
  components: {
    UpIcon,
  },
  setup() {
    // scroll to top
    const { isShownScrollToTop, handleScrollToTop } = useScrollToTop();

    return {
      handleScrollToTop,
      isShownScrollToTop,
    };
  },
});
