
import { defineComponent } from "vue";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import BayesLogo from "@/assets/images/bayes-logo.svg";
import DesignAward from "@/assets/images/design-award.svg";
import ResearchEnglandLogo from "@/assets/images/research-england-logo.svg";

export default defineComponent({
  components: {
    AppContent,
    Editorial,
    BayesLogo,
    DesignAward,
    ResearchEnglandLogo,
  },
  setup() {
    return {};
  },
});
