
import { defineComponent, ref, reactive, nextTick, PropType } from "vue";
import { storeToRefs } from "pinia";
import { useMessagesStore } from "@/stores/messages";
import { useSparksStore } from "@/stores/sparks";
import { useTopicsStore } from "@/stores/topics";
import { useTrackingStore } from "@/stores/tracking";
import { useModal } from "@/composables/useModal";
import type { SparkType } from "@/types/sparks";
import type { Topic } from "@/types/topics";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import TopicsSettings from "./TopicsSettings.vue";
import ExpandIcon from "@/assets/images/icon-expand.svg";
import TopicSettingsIcon from "@/assets/images/icon-topic-settings.svg";
import RefreshIcon from "@/assets/images/icon-refresh.svg";
import TickIcon from "@/assets/images/icon-tick.svg";
import CrossIcon from "@/assets/images/icon-cross.svg";

export default defineComponent({
  components: {
    ModalContent,
    TopicsSettings,
    ExpandIcon,
    TopicSettingsIcon,
    RefreshIcon,
    TickIcon,
    CrossIcon,
  },
  props: {
    method: {
      type: String as PropType<SparkType>,
      required: true,
    },
  },
  setup(props) {
    const messagesStore = useMessagesStore();
    const sparksStore = useSparksStore();
    const topicsStore = useTopicsStore();
    const trackingStore = useTrackingStore();

    const { isExpanded, topics, activeTopicsCount, activeTopicsSummary } =
      storeToRefs(topicsStore);

    const isAddingNewTopic = ref(false);
    const newTopic = ref("");
    const newTopicInput = ref<HTMLInputElement | null>(null);

    const modalState = reactive({ topicSettingsModal: false });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const getSparks = () => {
      sparksStore.getSparks({ type: props.method, clearAllSparks: true });
    };

    const handleTopicToggleActiveState = (topic: Topic) => {
      // if only one active topic remains, don't allow it to be deselected
      if (activeTopicsCount.value === 1 && topic.active) {
        messagesStore.addMessage("info", "LAST_ACTIVE_TOPIC");
      } else {
        topicsStore.selectTopic(topic.id);
        getSparks();
      }

      trackingStore.trackEvent({
        action: topic.active ? "TOPIC_SELECT" : "TOPIC_DESELECT",
      });
    };

    const handleToggleExpand = () => {
      topicsStore.setExpanded(!isExpanded.value);

      trackingStore.trackEvent({
        action: isExpanded.value ? "TOPICS_EXPAND" : "TOPICS_COLLAPSE",
      });
    };

    const handleShowAddNewTopicForm = async () => {
      isAddingNewTopic.value = true;

      // wait for the input to be rendered, then set focus for text input
      await nextTick();
      newTopicInput.value?.focus();
    };

    const handleHideAddNewTopicForm = () => {
      isAddingNewTopic.value = false;
    };

    const handleTopicSettingsClick = () => {
      showModal("topicSettingsModal");

      trackingStore.trackEvent({ action: "TOPICS_MODAL_SHOW" });
    };

    const handleTopicResetClick = () => {
      topicsStore.resetTopics();
      getSparks();

      trackingStore.trackEvent({ action: "TOPICS_RESET" });
    };

    const handleSaveNewTopic = async () => {
      if (newTopic.value.length === 0) return;
      const addTopic = await topicsStore.addUserTopic(newTopic.value);
      newTopic.value = "";
      isAddingNewTopic.value = false;

      if (!addTopic) return;
      getSparks();

      trackingStore.trackEvent({ action: "TOPIC_ADD" });
    };

    return {
      isExpanded,
      topics,
      activeTopicsSummary,
      isAddingNewTopic,
      newTopic,
      newTopicInput,
      isShown,
      showModal,
      closeModal,
      handleTopicToggleActiveState,
      handleToggleExpand,
      handleShowAddNewTopicForm,
      handleHideAddNewTopicForm,
      handleTopicSettingsClick,
      handleTopicResetClick,
      handleSaveNewTopic,
    };
  },
});
