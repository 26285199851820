import { ref } from "vue";
import { defineStore } from "pinia";

/* Define the store */

export const useGuidedTourStore = defineStore("guidedTour", () => {
  /**
   * State
   */
  const startTour = ref<boolean>(false);
  const finishTour = ref<boolean>(false);
  const exampleTextAndSubmitSet = ref<boolean>(false);
  const activeSparkTab = ref<string | null>(null);

  /**
   * Actions
   */
  function setStartTour(): void {
    startTour.value = true;
  }

  function setTourStarted(): void {
    startTour.value = false;
  }

  function setFinishTour(): void {
    finishTour.value = true;
  }

  function setTourFinished(): void {
    finishTour.value = false;
  }

  function setExampleTextAndSubmit(value: boolean): void {
    exampleTextAndSubmitSet.value = value;
  }

  function setActiveSparkTab(value: string): void {
    activeSparkTab.value = value;
  }

  return {
    startTour,
    finishTour,
    exampleTextAndSubmitSet,
    activeSparkTab,
    setStartTour,
    setTourStarted,
    setFinishTour,
    setTourFinished,
    setExampleTextAndSubmit,
    setActiveSparkTab,
  };
});
