
import { defineComponent, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useTrackingStore } from "@/stores/tracking";
import { useMenuStore } from "@/stores/menu";
import { useGuidedTourStore } from "@/stores/guidedTour";
import { navLinks } from "@/constants/menu";
import { useEventListener } from "@/composables/useEventListener";
import CloseIcon from "@/assets/images/icon-close.svg";

export default defineComponent({
  components: {
    CloseIcon,
  },
  setup() {
    const route = useRoute();
    const trackingStore = useTrackingStore();
    const menuStore = useMenuStore();
    const guidedTourStore = useGuidedTourStore();

    const { isShownMobileMenu } = storeToRefs(menuStore);

    const hideMobileMenu = () => {
      menuStore.setShownMobileMenu(false);

      trackingStore.trackEvent({ action: "MOBILE_MENU_HIDDEN" });
    };

    const hasTour = computed(() => route.meta.hasTour || false);

    const handleTakeATour = () => {
      guidedTourStore.setStartTour();
    };

    useEventListener(window, "click", hideMobileMenu);

    return {
      navLinks,
      isShownMobileMenu,
      hideMobileMenu,
      hasTour,
      handleTakeATour,
    };
  },
});
