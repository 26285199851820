import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuidedTourContent = _resolveComponent("GuidedTourContent")!

  return (_openBlock(), _createBlock(_component_GuidedTourContent, {
    ref: "guidedTourContentRef",
    steps: _ctx.steps,
    onFinish: _ctx.handleFinishTour
  }, null, 8, ["steps", "onFinish"]))
}