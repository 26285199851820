import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueUniversalModal from "vue-universal-modal";
import VueGtag from "vue-gtag";
import Toast, { TYPE } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import App from "@/App.vue";
import router from "@/router";
import "@/assets/sass/app.scss";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueUniversalModal, {
  teleportTarget: "#modals",
});

if (process.env.VUE_APP_ANALYTICS_ID) {
  app.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_ANALYTICS_ID,
    },
    enabled: false,
  });
}

const toastOptions = {
  position: "bottom-left",
  toastDefaults: {
    [TYPE.INFO]: {
      timeout: 5000,
    },
    [TYPE.ERROR]: {
      timeout: 3000,
    },
    [TYPE.SUCCESS]: {
      timeout: 10000,
    },
  },
};
app.use(Toast, toastOptions);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

app.mount("#app");
