
import { defineComponent, PropType } from "vue";
import CloseIcon from "@/assets/images/icon-close.svg";

export default defineComponent({
  components: {
    CloseIcon,
  },
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    return {};
  },
});
