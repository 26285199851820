import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { messageContents } from "@/constants/messages";
import type {
  MessageType,
  MessageContent,
  Message,
  MessageKey,
} from "@/types/messages";

/* Define the store */

export const useMessagesStore = defineStore("messages", () => {
  /**
   * State
   */
  const messages = ref<Message[]>([]);

  /**
   * Getters
   */
  const latestMessage = computed<void | Message>(() => {
    if (messages.value.length < 1) return;
    return messages.value[messages.value.length - 1];
  });

  /**
   * Actions
   */
  function addMessage(type: MessageType, key: MessageKey): void {
    const content: MessageContent = messageContents[key];
    const message: Message = { type, content };
    messages.value.push(message);
  }

  function hideMessage(): void {
    messages.value.pop();
  }

  return {
    messages,
    latestMessage,
    addMessage,
    hideMessage,
  };
});
