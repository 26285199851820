
import { defineComponent, computed } from "vue";
import { useSparksStore } from "@/stores/sparks";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import ProblemForm from "@/components/content/problem-form/ProblemForm.vue";
import GeneratedSparks from "@/components/content/generated-sparks/GeneratedSparks.vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";

export default defineComponent({
  components: {
    AppContent,
    ProblemForm,
    GeneratedSparks,
    GuidedTour,
  },
  setup() {
    const sparksStore = useSparksStore();

    const isShownSparksSection = computed(
      () => sparksStore.isShownSparksSection,
    );

    return {
      isShownSparksSection,
    };
  },
});
