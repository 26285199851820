import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:px-6 relative z-40" }
const _hoisted_2 = { class: "max-w-4xl mx-auto" }
const _hoisted_3 = { class: "-mt-16 bg-cebai-base-light border border-cebai-grey-mid p-6 md:p-9 rounded-2xl shadow-[0_0_20px_0_rgba(51,51,51,0.1)]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpIcon = _resolveComponent("UpIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["sticky z-50 w-full flex justify-end bottom-0 p-3 transition pointer-events-none", { invisible: !_ctx.isShownScrollToTop }])
    }, [
      _createElementVNode("button", {
        class: "pointer-events-auto rounded-full cursor-pointer transition duration-300 flex items-center justify-center border-2 p-1 border-cebai-grey-dark hover:border-cebai-base-dark text-cebai-grey-dark hover:text-cebai-base-dark",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScrollToTop && _ctx.handleScrollToTop(...args)))
      }, [
        _createVNode(_component_UpIcon, { class: "fill-current" })
      ])
    ], 2)
  ], 64))
}