/**
 * derived from two sources:
 * https://levelup.gitconnected.com/how-to-turn-settimeout-and-setinterval-into-promises-6a4977f0ace3
 * https://blog.openreplay.com/forever-functional-waiting-with-promises/
 *
 * Sleep for a certain length of time.
 *
 * @param { number } ms Length of time in milliseconds
 * @return { Promise<void> } only returns the promise
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
};

/**
 * derived from two sources:
 * https://levelup.gitconnected.com/how-to-turn-settimeout-and-setinterval-into-promises-6a4977f0ace3
 * https://blog.openreplay.com/forever-functional-waiting-with-promises/
 *
 * Wait until a condition becomes true. Aborts after a number of failed attempts.
 *
 * @param { function } condition The condition to be met (boolean)
 * @param { number } ms Number of milliseconds to wait before next function call
 * @param { number } attempts Number of attempts before aborting
 * @return { Promise<boolean> } true if condition met within the time
 */
export const waitUntil = (
  condition: () => boolean,
  ms: number = 100,
  attempts: number = 100,
): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const interval = setInterval(() => {
      if (condition()) {
        resolve(true);
        clearInterval(interval);
      } else if (attempts <= 1) {
        resolve(false);
        clearInterval(interval);
      }
      attempts--;
    }, ms);
  });
};
