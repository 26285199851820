import { nextTick } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { setPageTitle } from "@/utils/dom";

import RootLayout from "@/components/layout/root/Root.vue";
import HomeView from "@/components/views/home/Home.vue";
import AboutView from "@/components/views/about/About.vue";
import TechniquesView from "@/components/views/techniques/Techniques.vue";
import CookiePolicyView from "@/components/views/cookie-policy/CookiePolicy.vue";
import ErrorView from "@/components/views/error/Error.vue";
import ComponentLibraryView from "@/components/views/component-library/ComponentLibrary.vue";

declare module "vue-router" {
  interface RouteMeta {
    heading?: string;
    subheading?: string;
    hasTour?: boolean;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: RootLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
        meta: {
          heading: "Infinite ideas, limitless creativity",
          subheading:
            "Explore new perspectives and possibilities for your design process through AI generated sparks.",
          hasTour: true,
        },
      },
      {
        path: "about/",
        name: "about",
        component: AboutView,
        meta: {
          heading: "About Design Sparks",
        },
      },
      {
        path: "techniques/",
        name: "techniques",
        component: TechniquesView,
        meta: {
          heading: "Techniques",
        },
      },
      {
        path: "cookie-policy/",
        name: "cookie-policy",
        component: CookiePolicyView,
        meta: {
          heading: "Cookie Policy",
        },
      },
      {
        path: "component-library/",
        name: "component-library",
        component: ComponentLibraryView,
        meta: {
          heading: "Component Library",
        },
      },
    ],
  },
  {
    path: "/error/",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // maintain current scroll position when we are linking to a route where
    // we don't want to adjust the scroll position
    // (and when the user hasn't clicked the browser's back/next buttons)
    if (!savedPosition && to?.meta?.maintainScrollPosition) return;

    // by default, update scroll when navigating between views
    return savedPosition || { left: 0, top: 0 };
  },
});

// update HTML <title> content on route navigation
router.beforeEach(async (to, from, next) => {
  await nextTick();
  const title = to.meta.heading ? to.meta.heading : "";
  setPageTitle(title);
  next();
});

export default router;
