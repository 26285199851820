import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "absolute z-50 right-2 top-2 p-4 rounded-full hover:bg-cebai-grey-light transition" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = {
  key: 0,
  class: "border-t border-t-cebai-grey-mid pt-2 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["md:hidden flex items-center justify-center fixed w-full top-0 bottom-0 right-0 z-50 bg-cebai-base-light transition duration-300", {
      'translate-x-0 visible opacity-100': _ctx.isShownMobileMenu,
      'translate-x-full invisible opacity-0': !_ctx.isShownMobileMenu,
    }])
  }, [
    _createElementVNode("button", _hoisted_1, [
      _createVNode(_component_CloseIcon, { class: "w-4 h-4 fill-current" })
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navLinks, (link) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.name
        }, [
          _createVNode(_component_router_link, {
            to: { name: link.name },
            class: "block py-2 px-4 text-cebai-grey-dark hover:text-cebai-base-dark text-2xl",
            "exact-active-class": "text-cebai-grey-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link.label), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      (_ctx.hasTour)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
            _createElementVNode("button", {
              class: "py-2 px-4 text-cebai-grey-dark hover:text-cebai-base-dark text-2xl",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTakeATour && _ctx.handleTakeATour(...args)))
            }, " Take a tour ")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}