export const truncate = (
  text: string,
  maxLength = 150,
  separator = " ",
): string => {
  if (text.length <= maxLength) return text;
  return text.substr(0, text.lastIndexOf(separator, maxLength)) + "...";
};

export const capitaliseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// convert camelCase strings to kebab-case
// see: https://stackoverflow.com/a/63116134
export const kebabize = (str: string) => {
  return str
    .split("")
    .map((letter, index) => {
      return letter.toUpperCase() === letter
        ? `${index !== 0 ? "-" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
};

// convert camelCase to lower case sentence string
export const camelToLowerCase = (str: string) => {
  return kebabize(str).replace(/-/g, " ");
};

// convert a duration string (e.g. 23:45 or 1:23:45) to minutes (e.g. 83 mins)
export const calculateDuration = (duration: string): string => {
  const durationArray = duration.split(":");
  if (durationArray.length === 2) {
    return `${durationArray[0]} mins`;
  }
  if (durationArray.length === 3) {
    return `${
      parseInt(durationArray[0], 10) * 60 + parseInt(durationArray[1], 10)
    } mins`;
  }
  return "";
};

// convert kebab-case strings to snake_case
export const snakeize = (str: string) => {
  return str.replace(/-/g, "_");
};

export type UrlParams = {
  [key: string]: string;
};

// convert an object of values into a URL params string
export const generateUrlParamsString = (urlParams: UrlParams): string => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(urlParams)) {
    params.append(key, value);
  }

  // (match the behaviour of browser form submissions)
  return params
    .toString()
    .replace(/%20/g, "+") // replace all %-encoded spaces to the '+' character
    .replace(/%2C/g, ","); // replace all %-encoded ',' to the ',' character
};

// Check whether a string is a valid URL
export const isValidURL = (urlString: string): boolean => {
  let url;
  try {
    url = new URL(urlString);
  } catch (error) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};
