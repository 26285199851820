import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-cebai-grey-light text-cebai-base-dark p-5 relative w-11/12 sm:w-9/12 max-w-3xl" }
const _hoisted_2 = {
  key: 1,
  class: "font-normal text-base text-cebai-grey-dark mt-1 mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "hover:cursor-pointer p-2 absolute right-4 top-4 text-cebai-grey-dark hover:text-cebai-base-dark",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
    }, [
      _createVNode(_component_CloseIcon, { class: "w-4 w-4 fill-current" })
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          class: _normalizeClass(["font-bold text-xl md:text-2xl text-cebai-grey-dark", { 'mb-6': !_ctx.subTitle }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    (_ctx.subTitle)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.subTitle), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}