/**
 * The alert messages used in the messages store and throughout the app.
 * The message keys are exported as type MessageKey, a union of the object keys,
 * from the types file.
 * @constant
 */
export const messageContents = {
  SAVED: {
    title: "",
    body: "This has been saved",
  },
  SUCCESS: {
    title: "",
    body: "This was successful",
  },
  ERROR: {
    title: "",
    body: "Sorry, something went wrong. Please try again.",
  },
  NO_EXTRACTED_TOPICS_WORDS: {
    title: "Please provide further detail",
    body: "We were unable to find any relevant topics",
  },
  NO_EXTRACTED_TOPICS_PDF: {
    title: "Please try another PDF",
    body: "We were unable to find any relevant topics",
  },
  NO_EXTRACTED_TOPICS_URL: {
    title: "Please try another URL",
    body: "We were unable to find any relevant topics",
  },
  UPLOAD_NOT_PDF: {
    title: "",
    body: "Sorry, you can only upload PDF files",
  },
  UPLOAD_FILE_TOO_LARGE: {
    title: "",
    body: "Sorry, the maximum file size for a PDF is 3MB",
  },
  URL_NOT_VALID: {
    title: "Please enter a valid URL",
    body: "For example, the format should look like http://example.com or https://www.example.com/",
  },
  URL_ERROR: {
    title: "Please try another URL",
    body: "We were unable to process the url you provided",
  },
  PDF_ERROR: {
    title: "Please try another PDF",
    body: "We were unable to process the PDF you provided",
  },
  NO_EXTRACTED_TEXT_URL: {
    title: "Please try another URL",
    body: "We were unable to extract any text from the url you provided",
  },
  NO_EXTRACTED_TEXT_PDF: {
    title: "Please try another PDF",
    body: "We were unable to extract any text from the PDF you provided",
  },
  LAST_ACTIVE_TOPIC: {
    title: "Hold on a moment!",
    body: "You must have at least one topic selected",
  },
  COPIED_TO_CLIPBOARD: {
    title: "",
    body: "Content copied to clipboard",
  },
  ERROR_CLIPBOARD: {
    title: "",
    body: "Sorry, it is not possible to copy to clipboard in your browser",
  },
} as const;
