
import { defineComponent, PropType } from "vue";
import AddIcon from "@/assets/images/icon-add.svg";
import ArrowIcon from "@/assets/images/icon-arrow.svg";

export default defineComponent({
  components: {
    AddIcon,
    ArrowIcon,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    stepsCount: {
      type: Number,
      required: true,
    },
    previous: {
      type: Function as PropType<() => void>,
      required: true,
    },
    next: {
      type: Function as PropType<() => void>,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    exit: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ["finish"],
  setup() {
    return {};
  },
});
