/**
 * One of the topics settings options
 * @constant
 */
export const sortTypes = {
  top: "Common topics",
  rare: "Rare topics",
  random: "Random selection",
} as const;

/**
 * One of the topics settings options
 * @constant
 */
export const maxTopicsValues = [1, 3, 5, 7, 15, 25] as const;
