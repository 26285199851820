import { ref } from "vue";
import { defineStore } from "pinia";

/* Define the store */

export const useMenuStore = defineStore("menu", () => {
  /**
   * State
   */
  const isShownMobileMenu = ref<boolean>(false);

  /**
   * Actions
   */
  function setShownMobileMenu(isShown: boolean): void {
    isShownMobileMenu.value = isShown;
  }

  return {
    isShownMobileMenu,
    setShownMobileMenu,
  };
});
