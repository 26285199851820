import { AxiosPromise, AxiosProgressEvent } from "axios";
import { apiClient } from "@/utils/api-client";

export type OnUploadProgress = {
  (e: AxiosProgressEvent): void;
};

/* api calls */

// includes override of apiClient content-type
export const getProblemTextfromPdfApi = (
  formData: FormData,
  onUploadProgress: OnUploadProgress,
): AxiosPromise => {
  return apiClient.post(`/nlp/pdf`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    timeout: 300000, // allow 5 minutes for upload
    onUploadProgress,
  });
};
