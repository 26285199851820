import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "relative"
}
const _hoisted_3 = { class: "relative z-20 pointer-events-none pl-9 pr-4 pt-3 mb-[17px] rounded-md bg-cebai-grey-light outline outline-1 outline-cebai-grey-light focus:outline-cebai-primary transition-all text-lg md:text-xl text-cebai-grey-dark" }
const _hoisted_4 = { class: "flex justify-end mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_AutoResizeTextarea = _resolveComponent("AutoResizeTextarea")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ArrowIcon, { class: "z-30 absolute left-3 top-[17px] text-cebai-primary-default stroke-current stroke-2" }),
      (!_ctx.showTextarea)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "absolute block left-0 top-0 bottom-0 w-full z-10 cursor-text",
              onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowTextarea && _ctx.handleShowTextarea(...args))),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleShowTextarea && _ctx.handleShowTextarea(...args)))
            }, null, 32),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(" Enter your problem, or "),
              _createElementVNode("button", {
                id: "button-example",
                class: "pointer-events-auto pb-3 text-cebai-primary-default active:text-cebai-primary-active hover:underline decoration-1",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSetExample && _ctx.handleSetExample(...args)))
              }, " try an example ")
            ])
          ]))
        : (_openBlock(), _createBlock(_component_AutoResizeTextarea, {
            key: 1,
            ref: "textareaEl",
            modelValue: _ctx.problemText,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.problemText) = $event)),
            rows: 1,
            "max-rows": 10,
            class: "border-[12px] border-transparent placeholder-cebai-grey-dark bg-cebai-grey-light rounded-md resize-none outline outline-1 outline-cebai-grey-light focus:outline-cebai-primary-default transition-all duration-300 w-full pl-6 pr-4 text-lg md:text-xl",
            onBlur: _ctx.handleHideTextarea
          }, null, 8, ["modelValue", "onBlur"]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.hasProblemText)
        ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            type: "outline-dark",
            class: "mr-2",
            onClick: _ctx.clearAll
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Clear ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AppButton, {
        id: "button-generate-sparks",
        type: "primary",
        disabled: !_ctx.hasEnoughProblemText,
        "is-loading": _ctx.isLoadingTopics,
        onClick: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Generate Sparks ")
        ]),
        _: 1
      }, 8, ["disabled", "is-loading", "onClick"])
    ])
  ], 64))
}