import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative group" }
const _hoisted_2 = {
  key: 0,
  class: "absolute z-20 top-0 left-0 w-full h-full flex flex-col items-center justify-center text-cebai-grey-dark"
}
const _hoisted_3 = { class: "relative z-30 text-cebai-primary-default text-lg" }
const _hoisted_4 = {
  key: 1,
  class: "w-8 h-8"
}
const _hoisted_5 = { class: "flex justify-end mt-3" }
const _hoisted_6 = { ref: "fileFormEl" }
const _hoisted_7 = {
  ref: "fileInputEl",
  class: "block invisible h-0 overflow-hidden",
  type: "file",
  accept: "application/pdf"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoadingProblem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.uploadPercentage)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.uploadPercentage) + "% ", 1),
                  _createElementVNode("div", {
                    class: "absolute left-0 top-0 bottom-0 z-20 bg-cebai-grey-dark rounded-md opacity-50 transition-all duration-1000",
                    style: _normalizeStyle(`width: ${_ctx.uploadPercentage}%`)
                  }, null, 4)
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_LoadingIcon, { class: "fill-current" })
                ]))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        ref: "dropzoneEl",
        class: "block relative z-10 w-full",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDropzoneClick && _ctx.handleDropzoneClick(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["relative p-3 pl-9 rounded-md bg-cebai-grey-light outline outline-1 group-hover:outline-cebai-primary-default transition-all duration-300", {
          'outline-cebai-grey-light': !_ctx.isHovered,
          'outline-cebai-primary-default': _ctx.isHovered,
        }])
        }, [
          _createVNode(_component_ArrowIcon, { class: "absolute left-3 top-[19px] text-cebai-primary-default stroke-current stroke-2" }),
          _createElementVNode("p", {
            class: _normalizeClass(["bg-cebai-grey-light group-hover:text-cebai-base-dark transition-all duration-300 rounded-md text-left text-lg md:text-xl", {
            'text-cebai-grey-dark': !_ctx.isHovered && !_ctx.file,
            'text-cebai-base-dark': _ctx.isHovered || _ctx.file,
          }])
          }, [
            (_ctx.file)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.file.name), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" Click or drop (max size 3MB) ")
                ], 64))
          ], 2)
        ], 2)
      ], 512)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.file)
        ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            type: "outline-dark",
            class: "mr-2",
            onClick: _ctx.clearAll
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Clear ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AppButton, {
        type: "primary",
        disabled: _ctx.isSubmitDisabled,
        "is-loading": _ctx.isLoadingTopics,
        onClick: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Generate Sparks ")
        ]),
        _: 1
      }, 8, ["disabled", "is-loading", "onClick"])
    ]),
    _createElementVNode("form", _hoisted_6, [
      _createElementVNode("input", _hoisted_7, null, 512)
    ], 512)
  ], 64))
}