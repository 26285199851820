import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    id: _ctx.id,
    class: _normalizeClass(["transition text-sm sm:text-base text-center pb-2 md:w-auto flex md:flex-none justify-center items-center whitespace-nowrap", {
      'text-cebai-base-dark': _ctx.activeTab?.includes(_ctx.slug),
      'text-cebai-grey-dark': !_ctx.activeTab?.includes(_ctx.slug),
      'mr-3 sm:mr-6 last:mr-0': !_ctx.outlined,
      'mr-0.5 border-2 py-2 px-4 rounded-t-lg relative -bottom-0.5': _ctx.outlined,
      'hover:text-cebai-primary-default hover:underline active:text-cebai-primary-active':
        !_ctx.activeTab?.includes(_ctx.slug) && !_ctx.outlined,
      'border-cebai-grey-mid border-b-cebai-base-light bg-cebai-base-light':
        _ctx.activeTab?.includes(_ctx.slug) && _ctx.outlined,
      'border-transparent hover:bg-cebai-grey-light hover:text-cebai-primary-default hover:border-cebai-grey-mid':
        !_ctx.activeTab?.includes(_ctx.slug) && _ctx.outlined,
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTabClick && _ctx.handleTabClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}