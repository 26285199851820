
import { defineComponent, computed, watch, onMounted, PropType } from "vue";
import { useSavedSparksStore } from "@/stores/savedSparks";
import { useSparksStore } from "@/stores/sparks";
import { useTrackingStore } from "@/stores/tracking";
import type { SparkType } from "@/types/sparks";
import Topics from "@/components/content/topics/Topics.vue";
import SparkComponent from "@/components/content/spark/Spark.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    Topics,
    SparkComponent,
    AppButton,
    LoadingIcon,
  },
  props: {
    method: {
      type: String as PropType<SparkType>,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const savedSparksStore = useSavedSparksStore();
    const sparksStore = useSparksStore();
    const trackingStore = useTrackingStore();

    const { getIsSaved } = savedSparksStore;

    const sparks = computed(() => sparksStore.sparks[props.method]);
    const isLoading = computed(() =>
      sparksStore.isLoading.includes(props.method),
    );
    const hasError = computed(() =>
      sparksStore.hasError.includes(props.method),
    );

    const getSparks = () => {
      sparksStore.getSparks({ type: props.method, clearAllSparks: false });
    };

    const handleRefreshSparks = () => {
      getSparks();

      trackingStore.trackEvent({
        action: `SPARKS_${props.method.toUpperCase()}_REFRESH`,
      });
    };

    watch(
      () => props.activeTab,
      () => {
        if (
          sparks.value.length === 0 &&
          !isLoading.value &&
          props.activeTab === props.method
        ) {
          getSparks();
        }
      },
    );

    onMounted(() => {
      if (
        sparks.value.length === 0 &&
        !isLoading.value &&
        props.activeTab === props.method
      ) {
        getSparks();
      }
    });

    return {
      sparks,
      isLoading,
      hasError,
      getIsSaved,
      handleRefreshSparks,
    };
  },
});
