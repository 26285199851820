import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "pt-16 pb-8" }
const _hoisted_2 = { class: "max-w-screen-xl mx-auto px-6 md:flex md:justify-between" }
const _hoisted_3 = { class: "mb-6 md:mb-0" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "text-sm text-cebai-grey-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
          return (_openBlock(), _createElementBlock("li", {
            key: link.title,
            class: "mr-4 mb-2 inline-block text-sm"
          }, [
            (link.internal)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: { name: link.name },
                  class: "text-cebai-grey-dark"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: link.url,
                  target: "_blank",
                  class: "text-cebai-grey-dark"
                }, _toDisplayString(link.title), 9, _hoisted_4))
          ]))
        }), 128))
      ]),
      _createElementVNode("p", _hoisted_5, "© " + _toDisplayString(_ctx.year) + " CebAI", 1)
    ])
  ]))
}