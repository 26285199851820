import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import type { Topic, Topics } from "@/types/topics";

/* helpers */

export const buildTopicsSet = (topics: Topics): Topic[] => {
  const topicsList: Topic[] = [];
  Object.keys(topics).forEach((key, i) => {
    topics[key].id = i + 1;
    topics[key].active = true;
    topics[key].origin = "api";
    topicsList.push(topics[key]);
  });
  return topicsList;
};

/* api calls */

export const getTopicsApi = (text: string): AxiosPromise => {
  return apiClient.post(`/nlp/keywords`, { text });
};
