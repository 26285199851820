import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProblemForm = _resolveComponent("ProblemForm")!
  const _component_GeneratedSparks = _resolveComponent("GeneratedSparks")!
  const _component_GuidedTour = _resolveComponent("GuidedTour")!
  const _component_AppContent = _resolveComponent("AppContent")!

  return (_openBlock(), _createBlock(_component_AppContent, null, {
    default: _withCtx(() => [
      _createVNode(_component_ProblemForm),
      (_ctx.isShownSparksSection)
        ? (_openBlock(), _createBlock(_component_GeneratedSparks, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_GuidedTour)
    ]),
    _: 1
  }))
}