import { ref } from "vue";
import { defineStore } from "pinia";
import type { SortType, TopicSettings } from "@/types/topicsSettings";

/* Define the store */

export const useTopicsSettingsStore = defineStore("topicsSetings", () => {
  /**
   * State
   */
  const sortType = ref<SortType>("top");
  const maxTopics = ref<number>(5);
  const enhanceText = ref<boolean>(false);

  /**
   * Actions
   */
  function updateSettings(settings: TopicSettings): void {
    sortType.value = settings.sortType;
    maxTopics.value = settings.maxTopics;
    enhanceText.value = settings.enhanceText;
  }

  return {
    sortType,
    maxTopics,
    enhanceText,
    updateSettings,
  };
});
